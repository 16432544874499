import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useApplicationModals } from "app/common/hooks";
import {
  useCollector,
  usePaginatedCollector,
} from "app/integration/common/hooks";
import {
  BadgeButton,
  IconButton,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import { SearchInput } from "app/components/atoms/input";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const breadcrumbItems = [
  { label: "Settings", href: "#" },
  { label: "Product Master", href: "#" },
];

function ProductPage() {
  const navigate = useNavigate();
  const { openModal, setPayloads } = useApplicationModals();
  const [params, setParams] = useState({
    search: "",
    product_search: "",
    status: null,
    customer_id: null,
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    collect(params);
  }, [params]);

  const [
    products,
    setProducts,
    productsLoader,
    collect,
    productPaginationProps,
  ] = usePaginatedCollector({ modelName: "product", params: params });

  return (
    <MainLayout
      activeSidebarNavigation="product"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Daftar Produk"
      headingButtons={[
        {
          label: "Tambah Produk",
          type: "button",
          onClick: () => openModal("product.create"),
        },
      ]}
    >
      <AppModals
        items={["product.create", "product.update", "product.delete"]}
        onSuccess={{
          "product.create": () => collect(),
          "product.update": () => collect(),
          "product.delete": () => collect(),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <Table.SearchBarItemWrapper>
              <SearchInput
                placeholder="Cari Produk"
                type="text"
                value={params.product_search}
                // onChange={handleQueryChange}
                onChange={(e) =>
                  setParams({ ...params, product_search: e.target.value })
                }
              />
            </Table.SearchBarItemWrapper>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={false}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>Kode</Table.Heading>
              <Table.Heading>Nama</Table.Heading>
              <Table.Heading className="hidden md:table-cell">
                Berat
              </Table.Heading>
              <Table.Heading>Harga</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {products?.map((product, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>
                  <div className="flex items-center gap-2">
                    {product?.imageUrl && (
                      <ImageWithPreview
                        size="base"
                        src={`${process.env.REACT_APP_AWS_LINK_URL}${product?.imageUrl}`}
                      ></ImageWithPreview>
                    )}
                    {product?.code}
                  </div>
                </Table.Cell>
                <Table.Cell>{product?.name}</Table.Cell>
                <Table.Cell className="hidden md:table-cell">
                  {product?.weight}
                </Table.Cell>
                <Table.Cell>
                  <Typography.Currency number={product?.price} />
                </Table.Cell>
                <Table.Cell>
                  <div className="flex gap-1 items-center">
                    <BadgeButton
                      href={`/product/detail/${product.id}`}
                    >
                      Variant
                    </BadgeButton>
                    <IconButton
                      icon={PencilSquareIcon}
                      onClick={(e) => {
                        setPayloads("product.update", {
                          id: product.id,
                        });
                        openModal("product.update");
                      }}
                    />
                    {/* <IconButton
                      icon={TrashIcon}
                      onClick={(e) => {
                        setPayloads("product.delete", {
                          id: product.id,
                        });
                        openModal("product.delete");
                      }}
                    /> */}
                  </div>
                </Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
        <Table.PaginationWrapper>
          <ServerSidePaginationButtons
            paginationProps={productPaginationProps}
            params={params}
            setParams={setParams}
          />
        </Table.PaginationWrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default ProductPage;
