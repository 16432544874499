import clsx from "clsx";

function tableCellJustify(justify) {
  return {
    left: "text-left",
    center: "text-center",
    right: "text-right",
  }[justify];
}

function tableCellFontWeight(fontWeight) {
  return {
    light: "font-light",
    normal: "font-normal",
    semibold: "font-semibold",
    bold: "font-bold",
  }[fontWeight];
}

function tableCellSize(size) {
  return {
    xs: "px-1 py-1 print:py-1 ",
    sm: "px-2 py-2 print:py-1 ",
    md: "px-4 py-4 print:py-2",
    lg: "px-6 py-6 print:py-2 ",
  }[size];
}

function tableCellGradient(gradient) {
  return {
    900: "text-gray-900 dark:text-white",
    800: "text-gray-800 dark:text-gray-100",
    700: "text-gray-700 dark:text-gray-200",
    600: "text-gray-600 dark:text-gray-300",
    500: "text-gray-500 dark:text-gray-400",
    400: "text-gray-400 dark:text-gray-500",
    300: "text-gray-300 dark:text-gray-600",
    200: "text-gray-200 dark:text-gray-700",
    100: "text-gray-100 dark:text-gray-800",
  }[gradient];
}

function tableCellBackground(background) {
  return {
    900: "bg-gray-900 dark:bg-white",
    800: "bg-gray-800 dark:bg-gray-100",
    700: "bg-gray-700 dark:bg-gray-200",
    600: "bg-gray-600 dark:bg-gray-300",
    500: "bg-gray-500 dark:bg-gray-400",
    400: "bg-gray-400 dark:bg-gray-500",
    300: "bg-gray-300 dark:bg-gray-600",
    200: "bg-gray-200 dark:bg-gray-700",
    100: "bg-gray-100 dark:bg-gray-800",
    0: "bg-white dark:bg-black",
  }[background];
}

function tableCellClassName(className) {
  return className;
}

function TableCell({
  children,
  justify,
  gradient = 900,
  fontWeight = "normal",
  className = "",
  size = "sm",
  background = 0,
  align = "",
}) {
  return (
    <td
      className={clsx(
        "text-sm font-normal",
        tableCellJustify(justify),
        tableCellFontWeight(fontWeight),
        tableCellGradient(gradient),
        tableCellClassName(className),
        tableCellSize(size),
        // tableCellBackground(background),
        align === "right" ? "text-right" : ""
      )}
    >
      {children}
    </td>
  );
}

export default TableCell;
