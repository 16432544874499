import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Document, Image, PDFDownloadLink, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  logo: {
    height: "6cm",
    width: "80%",
    objectFit: "cover",
    margin: "auto",
  },
  logoContainer: {
    marginTop: 8,
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  orderInfo:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 14,
  },
  customerInfoContainer: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  customerInfo: {
    paddingLeft: 12
  },
  extraInfo: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    textTransform: "uppercase",
    marginTop: 32,
  },
  light: {
    fontWeight: 300,
    fontSize: 12,
  },
  label: {
    fontSize: 12,
    color: "#9CA3AF"
  },
  customerInfoWithLabel: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }
});

const setAddress = (address) => {
  if (address) {
    const {
      city_name,
      desc,
      district_name,
      state_name,
      sub_district_name,
      zipcode,
    } = address;

    let strAddress = desc;
    sub_district_name && (strAddress += ", \n" + "kel: " + sub_district_name);
    district_name && (strAddress += ", " + "kec: " + district_name);
    city_name && (strAddress += " \n" + "" + city_name);
    state_name && (strAddress += ", " + "" + state_name);
    zipcode && (strAddress += ", " + "" + zipcode);

    return strAddress;
    // return `${desc}, kel: ${sub_district_name}, kec: ${district_name}, ${city_name}, ${state_name}, ${zipcode}`;
  }

  return "";
};

const phoneNumber = process.env.REACT_APP_PHONE_NUMBER;
const companyName = process.env.REACT_APP_CLIENT_NAME;

const PDFGeneratorComponent = ({ data }) => {
  const [pdfDocument, setPdfDocument] = useState(null);
  useEffect(() => {
  const generatePdf = async () => {

    const MyDocument = (
      <Document>
        {data.map((object, index) => (
          <Page key={index} style={styles.page}>
            <View style={styles.section}>
              <View style={styles.orderInfo}>
                <Text>{object?.code}</Text>
                <Text>{object?.SalesOrder?.order_code}</Text>
              </View>
              <View style={styles.orderInfo}>
                <Text>{object?.SalesOrder?.courier_package}</Text>
                <Text style={styles.light}>{object?.MUser?.email}</Text>
              </View>
              <View style={styles.logoContainer}>
                <Image style={styles.logo} src="/client-logo-square.png" />
              </View>
              <View style={styles.customerInfoContainer}>
                <View style={styles.customerInfoWithLabel}>
                  <Text style={styles.label}>Nama:</Text>
                  <Text style={styles.customerInfo}>{object?.MCustomer?.firstName} {object?.MCustomer?.middleName} {object?.MCustomer?.lastName}</Text>
                </View>
                <View style={styles.customerInfoWithLabel}>
                  <Text style={styles.label}>Alamat Lengkap:</Text>
                  <Text style={styles.customerInfo}>{setAddress(object?.SalesOrder?.Address)}</Text>
                </View>
                <View style={styles.customerInfoWithLabel}>
                  <Text style={styles.label}>Telp:</Text>
                  <Text style={styles.customerInfo}>{object?.MCustomer?.phone}</Text>
                </View>
                <View style={styles.customerInfoWithLabel}>
                  <Text style={styles.label}>Total Items:</Text>
                  <Text style={styles.customerInfo}>
                    {object?.OrderItems?.map((orderDetail) => orderDetail.qty).reduce(
                      (partialSum, a) => partialSum + a,
                      0
                    )}{" "}
                    pcs
                  </Text>
                </View>
              </View>
              <View style={styles.extraInfo}>
                <Text>!! INFO PENTING !!</Text>
                <Text>Segala bentuk komplain harus disertakan video unboxing (tanpa
                  terputus) dari awal paket belum dibuka sampai dipastikan pesanan
                  sesuai. Jika tidak ada, mohon maaf, komplain tidak dapat dilayani.</Text>
                <Text>Thank you for shopping with us!</Text>
                <Text>From: {companyName} ❤</Text>
                <Text>Telp: {phoneNumber}</Text>
              </View>
            </View>
          </Page>
        ))}
      </Document>
    );

    setPdfDocument(MyDocument);
  };
  generatePdf();
}, [data]);

  return (
    <div className='flex justify-end md:justify-start'>
      {pdfDocument ? (
      <PDFDownloadLink document={pdfDocument} fileName="DeliverySticker.pdf">
        <ArrowDownTrayIcon className='h-6 w-6 text-gray-500' />
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
      </PDFDownloadLink>
      ) : (
        <div>Loading PDF...</div>
      )}
    </div>
  );
};

export default PDFGeneratorComponent;
