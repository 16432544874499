import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import {
  BadgeButton,
  IconButton,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import { Input } from "app/components/atoms/input";
import { FormHeader } from "app/components/atoms/layout";
import { SelectSearch } from "app/components/atoms/select";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import {
  useCollector,
  usePaginatedCollector,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

const breadcrumbItems = [
  { label: "Sales", href: "/sales_order" },
  { label: "Antrian Produk", href: "/sales_order/antrian_produk" },
  { label: "Detail", href: "#" },
];

function CustomerOrderQueuePage() {
  const {
    product_code,
    color_name,
    size_name,
    product_id,
    color_variant_id,
    size_variant_id,
    min_total,
  } = useParams();
  const default_limit = process.env.REACT_APP_DEFAULT_LIMIT_QUEUE;
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();
  const typingTimer = useRef(null);
  const doneTypingInterval = 2000;

  const [code, setCode] = useState("");

  const [params, setParams] = useState({
    product_search: "",
    product_id: product_id,
    color_variant_id: color_variant_id,
    size_variant_id: size_variant_id,
    page: 1,
    limit: default_limit,
  });

  const [
    orderItems,
    setOrderItems,
    orderItemsLoader,
    collectOrderItems,
    orderItemPaginationProps,
  ] = usePaginatedCollector({
    modelName: "orderItem",
    methodName: "customerOrderQueue",
    params: params,
  });

  useEffect(() => {
    collectOrderItems(params);
  }, []);

  const handleKeyUp = () => {
    clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(doneTyping, doneTypingInterval);
  };

  const handleKeyDown = () => {
    clearTimeout(typingTimer.current);
  };

  const doneTyping = () => {
    let set_limit = default_limit;
    if (code === "") {
      set_limit = default_limit;
    } else {
      set_limit = 10000;
    }
    setParams({
      ...params,
      product_search: code,
      limit: set_limit,
    });
    collectOrderItems(params);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return "gray";
      case 2:
        return "yellow";
      case 3:
        return "green";
      case 4:
        return "green";
      case 5:
        return "yellow";
      default:
        return "gray";
    }
  };

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return "Tunggu Stok";
      case 2:
        return "Siap Packing";
      case 3:
        return "Siap dikirim";
      case 4:
        return "Sudah Dikirim";
      case 5:
        return "Pending";
      default:
        return "Unknown";
    }
  };

  const getTotalMin = (item) => {
    const buying_list = item?.DeliveryOrder?.buying_list;
    if (!buying_list || buying_list == "") {
      return 0;
    } else {
      let total = 0;

      let full_product_name = `${product_code} ${color_name} ${size_name}`;

      let buying_list_array = buying_list.split(",");
      buying_list_array.forEach((buying_item) => {
        let buying_item_array = buying_item.split(":");
        let buying_product_name = buying_item_array[0].trim();
        let buying_qty = buying_item_array[1].trim().replace("pcs", "");
        if (buying_product_name === full_product_name) {
          total = Number(buying_qty);
        }
      });

      return total;
    }
  };

  const customerArr = (item) => {
    const totalQty = item?.qty;
    let reduceQty = item?.qty;
    let totalMin = Math.abs(getTotalMin(item));

    const arr = [];
    for (let i = 0; i < totalQty; i++) {
      let temp = {
        ...item,
        qty: 1,
        min: reduceQty > totalMin ? 0 : 1,
      };
      arr.push(temp);
      reduceQty -= 1;
    }

    return arr;
  };

  let rowIndex = 1;

  return (
    <MainLayout
      activeSidebarNavigation="sales"
      activeSidebarChild="product_queue"
      breadcrumbItems={breadcrumbItems}
      pageTitle={`Antrian ${product_code} - ${color_name} - ${size_name}`}
      headingButtons={[]}
    >
      <Table.Container>
        <Table.Wrapper asIndex={false}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>No.</Table.Heading>
              <Table.Heading>Supplier</Table.Heading>
              <Table.Heading>Customer</Table.Heading>
              <Table.Heading>Status Antrian</Table.Heading>
              {/* <Table.Heading>Belum Di beli</Table.Heading> */}
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {orderItems?.map((list, index) => {
              let custArr = customerArr(list);
              let tempElement = (
                <>
                  {custArr.length > 0
                    ? custArr.map((item, key) => (
                      <Table.BodyRow key={key}>
                        <Table.Cell>
                          <div className="flex items-center gap-2">
                          <p>{rowIndex++}.</p>
                          <BadgeButton
                            href={"/sales_order/update/" + item?.sales_order_id}
                          >
                            {item?.SalesOrder.order_code}
                            </BadgeButton>
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                          <p>{item?.master_product?.MSupplier?.firstName}</p>
                        </Table.Cell>

                        <Table.Cell>
                          <a
                            href={"/customer/detail/" + item?.SalesOrder?.customer_id}
                            className="hover:underline hover:text-blue-600 font-medium"
                          >
                            {item?.SalesOrder?.MCustomer?.firstName}{" "}
                            {item?.SalesOrder?.MCustomer?.middleName}{" "}
                            {item?.SalesOrder?.MCustomer?.lastName}
                            {item?.SalesOrder?.delivery_to !==
                              item?.SalesOrder?.customer_id &&
                              item?.SalesOrder?.MCustomer?.nickName !== null &&
                              item?.SalesOrder?.MCustomer?.nickName !== "" &&
                              " (" +
                              item?.SalesOrder?.MCustomer?.nickName +
                              ")"}
                          </a>
                        </Table.Cell>

                        <Table.Cell>
                          {item?.DeliveryOrder?.status != null && (
                            <>
                              <IconButton
                                icon={item?.min == 0 ? CheckCircleIcon : XCircleIcon}
                                color={item?.min == 0 ? "green" : "red"}
                              />
                            </>
                          )}
                        </Table.Cell>
                        {/* <Table.Cell>{item?.min}</Table.Cell> */}
                      </Table.BodyRow>
                    ))
                    : null}
                </>);
              return tempElement;
            })}
          </Table.Body>
        </Table.Wrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default CustomerOrderQueuePage;
