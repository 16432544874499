import { ArrowPathIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useApplicationModals } from "app/common/hooks";
import {
  Button,
  DottedOutlineButton,
  IconButton,
} from "app/components/atoms/button";
import { InputLabel } from "app/components/atoms/form";
import { FormHeader, FormSummary } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import {
  ProductSelectSearchMolecule,
  SelectMolecule,
  SelectSearchMolecule,
} from "app/components/molecules/select";
import { ControlledTab } from "app/components/molecules/tab";
import api from "app/integration/api";
import {
  useCollector,
  useFetcher,
  useUpdateForm,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik, getIn } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "style/height.module.css";
import * as Yup from "yup";
import { isEmpty } from "underscore";

const breadcrumbItems = [
  { label: "Sales", href: "/sales_order" },
  { label: "Order", href: "/sales_order" },
  { label: "Ubah Order", href: "#" },
];

function UpdateSalesOrderEditRequestPage() {
  let { id, tabCode } = useParams();
  const navigate = useNavigate();
  let isError = false;

  const [productsAll, setProductsAll] = useState([]);
  const [addresses, setAddress] = useState([]);
  const [delivery_fee, setDeliveryFee] = useState(0);
  const [delivery_fee_before, setDeliveryFeeBefore] = useState(0);
  const [selectedCourier, setSelectedCourier] = useState("");
  const [selectedCourierBefore, setSelectedCourierBefore] = useState("");
  const [needUpdateDeliveryFee, setNeedUpdateDeliveryFee] = useState(false);

  const handleSuccess = () => {
    navigate("/sales_order_edit_request");
  };

  const checkNew = (
    old_size_variant_id,
    old_color_variant_id,
    old_product_id,
    product_id,
    size_variant_id,
    color_variant_id
  ) => {
    let count = 0;
    if (old_size_variant_id != size_variant_id) count++;
    if (old_color_variant_id != color_variant_id) count++;
    if (old_product_id != product_id) count++;

    console.log(
      old_size_variant_id,
      old_color_variant_id,
      old_product_id,
      product_id,
      size_variant_id,
      color_variant_id,
      count
    );

    return count;
  };

  const duplicateProductCheck = (products) => {
    // console.log("products: ", products);
    // check for duplicate object
    const seen = new Set();
    if (products) {
      for (const product of products) {
        if (isEmpty(product)) continue;
        const key = `${product.product_id}_${product.size_variant_id}_${product.color_variant_id}`;
        if (seen.has(key)) {
          // console.log("duplicate found!");
          return false;
        }
        seen.add(key);
      }
    }
    return true;
  };

  const [isLoading, setIsLoading] = useState(false);
  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        customer_id: null,
        address_id: null,
        delivery_fee: 0,
        note_order: "",
        courier_package: "",
        items: [
          {
            id: null,
            product_id: null,
            size_variant_id: null,
            color_variant_id: null,
            qty: 1,
            old_qty: 1,
            unit_price: 0,
            discount_reseller: 0,
            weight: 0,
            old_product_id: null,
            old_color_variant_id: null,
            old_size_variant_id: null,
          },
        ],
        order_code: "",
        status: 1,
      },
      validationSchema: Yup.object().shape({
        customer_id: Yup.number().required("Customer harus diisi"),
        address_id: Yup.number().required("Alamat harus diisi"),
        delivery_fee: Yup.number(),
        note_order: Yup.string(),
        items: Yup.array()
          .of(
            Yup.object().shape({
              product_id: Yup.number().required("Produk harus diisi"),
              size_variant_id: Yup.number().required("Size harus diisi"),
              color_variant_id: Yup.number().required("Warna harus diisi"),
              qty: Yup.number().required("Qty harus diisi").min(1, "minimal 1"),
              unit_price: Yup.number().required("Harga harus diisi"),
              discount_reseller: Yup.number()
                .min(0, "Diskon 0-" + process.env.REACT_APP_MAX_DISCOUNT)
                .max(
                  Number(process.env.REACT_APP_MAX_DISCOUNT),
                  "Diskon 0-" + process.env.REACT_APP_MAX_DISCOUNT
                ),
            })
          )
          .min(1, "Minimal harus ada 1 Item yang dibeli.")
          .test(
            "Unique",
            "Terdapat produk dengan varian yang sama. Jika varian sama persis, harap menambah melalui QTY.",
            (values) => {
              return duplicateProductCheck(values);
            }
          ),
      }),
      onSubmit: async (values) => {
        if (needUpdateDeliveryFee) {
          setPayloads("errorMessage.view", {
            title: "Update Sales Order Error",
            message: "Harap kalkulasi ulang ongkir terlebih dahulu.",
          });
          openModal("errorMessage.view");
          isError = true;
        } else {
          try {
            setIsLoading(true);
            const response = await api.requestApprove.update(
              values.id,
              values.customer_id,
              values.address_id,
              delivery_fee,
              values.note_order,
              values.courier_package,
              values.items
                .filter((orderDetail) => !isEmpty(orderDetail))
                .map((orderDetail) => ({
                  id:
                    checkNew(
                      orderDetail.old_size_variant_id,
                      orderDetail.old_color_variant_id,
                      orderDetail.old_product_id,
                      orderDetail.product_id,
                      orderDetail.size_variant_id,
                      orderDetail.color_variant_id
                    ) == 0
                      ? orderDetail.id
                      : null,
                  // id: orderDetail.id,
                  product_id: orderDetail.product_id,
                  size_variant_id: orderDetail.size_variant_id,
                  color_variant_id: orderDetail.color_variant_id,
                  qty: orderDetail.qty,
                  old_id: orderDetail.old_id,
                  old_qty:
                    checkNew(
                      orderDetail.old_size_variant_id,
                      orderDetail.old_color_variant_id,
                      orderDetail.old_product_id,
                      orderDetail.product_id,
                      orderDetail.size_variant_id,
                      orderDetail.color_variant_id
                    ) == 0
                      ? orderDetail.old_qty
                      : null,
                  unit_price: orderDetail.unit_price,
                  discount_reseller: orderDetail.discount_reseller,
                  weight: orderDetail.weight,
                })),
              values.status
            );
            isError = false;
            return response;
          } catch (error) {
            setPayloads("errorMessage.view", {
              title: "Pengajuan Edit Sales Order",
              message: error?.response?.data?.message
                ? error?.response?.data?.message
                : "Server Error",
            });
            isError = true;
            openModal("errorMessage.view");
            setIsLoading(false);
          }
        }
      },
      onSuccess: () => {
        if (!isError) {
          handleSuccess();
        }
      },
    });

  const [salesOrderEditRequest, setSalesOrderEditRequest, loader, fetch] =
    useFetcher({
      modelName: "salesOrderEditRequest",
      payload: { id },
    });

  const { openModal, setPayloads } = useApplicationModals();
  const [currentErrors, setCurrentErrors] = useState([]);

  async function runValidations(values, setFieldValue, status) {
    await setFieldValue("status", status);
    validationSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
        setCurrentErrors([]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.name); // ValidationError
        console.log(err.errors);
        setCurrentErrors(err.errors);

        setPayloads("errorMessage.view", {
          title: "Ubah Order Error",
          data: err.errors,
        });
        openModal("errorMessage.view");
      });
  }

  // const [customerParams, setCustomerParams] = useState({
  //   search: "",
  //   page: 1,
  //   limit: Number(process.env.REACT_APP_CUSTOMER_LIMIT),
  // });
  // const [customers, setMCustomers, customersLoader, collectMCustomers] =
  //   useCollector({ modelName: "customer", params: customerParams });

  // useEffect(() => {
  //   collectMCustomers(customerParams);
  // }, [customerParams]);

  const [dailyMenus] = useCollector({ modelName: "dailyMenu" });

  const localHelpers = {
    subtotal: (orderItems) => {
      if (orderItems && orderItems.length > 0) {
        let subtotal = orderItems
          .map(
            (orderItem) =>
              Number(orderItem.qty) *
              (Number(orderItem.unit_price) -
                Number(orderItem.discount_reseller))
          )
          .reduce((partialSum, a) => partialSum + a, 0);

        return subtotal;
      }
    },
    totalWeight: (orderItems) => {
      if (orderItems && orderItems.length > 0) {
        let totalWeightOrder = orderItems
          .map((orderItem) => +orderItem.qty * +orderItem.weight)
          .reduce((partialSum, a) => partialSum + a, 0);

        // console.log("Weight before roundup: ", totalWeightOrder);
        // Round up by 1000
        totalWeightOrder = Math.ceil(totalWeightOrder / 1000) * 1000;
        // console.log("Weight after roundup: ", totalWeightOrder);

        return +totalWeightOrder;
      }
    },
  };

  const getAddress = async (customer_id = 1) => {
    const response = await api.address.index({ customer_id });

    if (response && response.data) {
      let convert = response.data.map((list) => ({
        value: list.id,
        label: list.desc,
      }));
      setAddress(convert);
    }
  };

  function getUniqueData(product, product_id, variant, selected_variant_id) {
    let uniqueData = {};
    let isDisabled = false;

    product?.ProductVariants?.forEach((item) => {
      if (variant === "color" && selected_variant_id > 0) {
        if (
          product?.ProductVariants?.find(
            (option) =>
              option.size.id === selected_variant_id &&
              option.color.id === item[`${variant}`].id
          ) === undefined
        ) {
          isDisabled = true;
        } else {
          isDisabled = false;
        }
      }
      if (variant === "size" && selected_variant_id > 0) {
        if (
          product?.ProductVariants?.find(
            (option) =>
              option.color.id === selected_variant_id &&
              option.size.id === item[`${variant}`].id
          ) === undefined
        ) {
          isDisabled = true;
        } else {
          isDisabled = false;
        }
      }
      uniqueData[item[`${variant}`].name] = {
        id: item[`${variant}`].id,
        name: item[`${variant}`].name,
        disabled: isDisabled,
      };
    });

    let convertData = Object.entries(uniqueData).map((item) => {
      return {
        value: item[1].id,
        label: item[1].name,
        disabled: item[1].disabled,
      };
    });

    return convertData;
  }

  // const getProduct = async () => {
  //   const response = await api.product.index();

  //   if (response && response.data && response.data.length > 0) {
  //     setProduct(response.data);
  //   }
  // };

  async function getCustomerAddress(address_id) {
    const response = await api.address.show(address_id);
    return response?.data?.city_id;
  }

  async function getDeliveryFee(address_id, items, type) {
    let destination = await getCustomerAddress(address_id);
    let totalWeightOrder = items
      .map((orderItem) => +orderItem.qty * +orderItem.weight)
      .reduce((partialSum, a) => partialSum + a, 0);

    // Round up by 1000
    totalWeightOrder = Math.ceil(totalWeightOrder / 1000) * 1000;

    if (totalWeightOrder <= 0) {
      setPayloads("errorMessage.view", {
        title: "Get Ongkir Error",
        message:
          "Tidak dapat kalkulasi ongkir jika berat produk 0. Harap set berat produk dengan benar.",
      });
      isError = true;
      openModal("errorMessage.view");
      return;
    }

    if (destination && totalWeightOrder > 0) {
      const response = await api.rajaOngkir.domestic(
        destination,
        totalWeightOrder
      );

      if (response && response.data.length > 0) {
        let filtered = response.data[0]?.costs.filter(
          (i) => i.service == type
        )[0];

        if (!filtered) {
          setDeliveryFee(0);
          setNeedUpdateDeliveryFee(true);
          alert("Kurir tidak tersedia");
        } else {
          setDeliveryFee(filtered?.cost[0]?.value);
          setNeedUpdateDeliveryFee(false);
        }
      }
    }
  }

  // useEffect(() => {
  //   getProduct();
  // }, []);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleProductSelected = (selectedProduct, key) => {
    // Save selectedProduct into an array of selectedProducts based on index key
    if (selectedProduct !== null && selectedProduct !== undefined) {
      setSelectedProducts((prevSelectedProducts) => {
        const updatedSelectedProducts = [...prevSelectedProducts];
        updatedSelectedProducts[key] = selectedProduct;
        return updatedSelectedProducts;
      });
    }
  };

  const updateProductPrice = (selectedProduct, key, values) => {
    values.items[`${key}`].unit_price = selectedProduct?.price;
    values.items[`${key}`].weight = selectedProduct?.weight;
    values.items[`${key}`].imageUrl = selectedProduct?.imageUrl;
  };

  useEffect(() => {
    console.log("selectedProducts: ", selectedProducts);
  }, [selectedProducts]);

  useEffect(() => {
    getAddress(salesOrderEditRequest?.so_after?.[0].customer_id);
    setDeliveryFee(salesOrderEditRequest?.so_after?.[0].delivery_fee);
    setDeliveryFeeBefore(salesOrderEditRequest?.so_before?.[0].delivery_fee);
    setSelectedCourier(salesOrderEditRequest?.so_after?.[0].courier_package);
    setSelectedCourierBefore(
      salesOrderEditRequest?.so_before?.[0].courier_package
    );
    setInitialValues({
      id: salesOrderEditRequest?.id,
      customer_id: salesOrderEditRequest?.so_after?.[0].customer_id,
      address_id: salesOrderEditRequest?.so_after?.[0].address_id,
      note_order: salesOrderEditRequest?.so_after?.[0].note_order,
      courier_package: salesOrderEditRequest?.so_after?.[0].courier_package,
      items: salesOrderEditRequest?.so_after?.[0]?.oi_after?.map(
        (orderItem, index) => ({
          id: orderItem.id,
          size: getUniqueData(
            selectedProducts[index],
            orderItem.product_id,
            "size"
          ),
          color: getUniqueData(
            selectedProducts[index],
            orderItem.product_id,
            "color"
          ),
          size_variant_id: orderItem.size_variant_id,
          color_variant_id: orderItem.color_variant_id,
          product_id: orderItem.product_id,
          imageUrl: orderItem?.master_product?.imageUrl,
          qty: orderItem.qty,
          old_id: orderItem.old_id,
          old_qty: orderItem.old_qty,
          unit_price: orderItem.unit_price,
          discount_reseller: orderItem.discount_reseller,
          notes: orderItem.notes ? orderItem.notes : "",
          weight: orderItem.weight,
          old_product_id: orderItem.product_id,
          old_size_variant_id: orderItem.size_variant_id,
          old_color_variant_id: orderItem.color_variant_id,
        })
      ),
      order_code: salesOrderEditRequest?.so_after?.[0]?.order_code,
    });

    salesOrderEditRequest?.so_after?.[0]?.oi_after?.map((orderItem, index) => {
      setSelectedProducts((prevSelectedProducts) => {
        const updatedSelectedProducts = [...prevSelectedProducts];
        updatedSelectedProducts[index] = orderItem;
        return updatedSelectedProducts;
      });
    });
  }, [salesOrderEditRequest]);

  return (
    <MainLayout
      activeSidebarNavigation="order"
      breadcrumbItems={breadcrumbItems}
      pageTitle={
        <div className="flex items-center gap-2">
          Request Edit {initialValues?.order_code}
        </div>
      }
      headingButtons={[]}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
          >
            <ControlledTab
              withPadding={false}
              opened={tabCode}
              onClickTab={(e) => {
                navigate(
                  `/sales_order_edit_request/update/${id}/${e.target.tabCode}`
                );
              }}
              tabs={[
                {
                  code: "before",
                  title: "BEFORE UPDATE",
                  element: (
                    <div className="mt-2">
                      <FormHeader>
                        {/* <SelectSearchMolecule
                          label="Customer"
                          name="customer_id"
                          options={customers?.map((customer) => ({
                            value: customer.id,
                            label:
                              customer.firstName +
                              " " +
                              customer.middleName +
                              " " +
                              customer.lastName,
                          }))}
                          onChange={(e) => {
                            handleChange(e);
                            getAddress(e.target.value);
                            setNeedUpdateDeliveryFee(true);
                          }}
                          onSearch={(text) => {
                            setCustomerParams({
                              ...customerParams,
                              search: text,
                            });
                          }}
                          apiSearch={true}
                          onBlur={handleBlur}
                          value={
                            salesOrderEditRequest?.so_before?.[0]?.customer_id
                          }
                          errorMessage={
                            errors.customer_id &&
                            touched.customer_id &&
                            errors.customer_id
                          }
                          disabled={true}
                        /> */}

                        <div>
                          <InputLabel>Customer</InputLabel>
                          <p className="px-2 py-2">
                            {salesOrderEditRequest?.so_before?.[0].MCustomer
                              ?.firstName +
                              " " +
                              salesOrderEditRequest?.so_before?.[0].MCustomer
                                ?.middleName +
                              " " +
                              salesOrderEditRequest?.so_before?.[0].MCustomer
                                ?.lastName}
                          </p>
                        </div>
                        <SelectMolecule
                          label="Alamat Kirim"
                          name="address_id"
                          options={addresses}
                          onChange={(e) => {
                            handleChange(e);
                            setNeedUpdateDeliveryFee(true);
                          }}
                          onBlur={handleBlur}
                          value={
                            salesOrderEditRequest?.so_before?.[0]?.address_id
                          }
                          errorMessage={
                            errors.address_id &&
                            touched.address_id &&
                            errors.address_id
                          }
                          disabled={true}
                        />
                        <SelectMolecule
                          label="Kurir"
                          name="courier_package"
                          options={[
                            {
                              value: "JAGOPACK",
                              label: "JAGOPACK",
                            },
                            {
                              value: "REGPACK",
                              label: "REGPACK",
                            },
                            {
                              value: "BIGPACK",
                              label: "BIGPACK",
                            },
                            {
                              value: "BOSSPACK",
                              label: "BOSSPACK",
                            },
                          ]}
                          value={
                            salesOrderEditRequest?.so_before?.[0]
                              ?.courier_package
                          }
                          onChange={(e) => {
                            handleChange(e);
                            getDeliveryFee(
                              values.address_id,
                              values.items,
                              e.target.value
                            );
                            setSelectedCourier(e.target.value);
                            setNeedUpdateDeliveryFee(true);
                          }}
                          disabled={true}
                        />
                      </FormHeader>
                      <div className="py-2 overflow-y-visible">
                        <div className="w-full">
                          <Table.Wrapper asIndex={false} overflow={false}>
                            <Table.Header>
                              <Table.HeaderRow>
                                <Table.Heading>Product</Table.Heading>
                                <Table.Heading>Size</Table.Heading>
                                <Table.Heading>Warna</Table.Heading>
                                {/* <Table.Heading>Catatan</Table.Heading> */}
                                <Table.Heading>Qty</Table.Heading>
                                <Table.Heading>
                                  <p className="text-right">Harga Satuan</p>
                                </Table.Heading>
                                <Table.Heading>
                                  <p className="text-right">Diskon</p>
                                </Table.Heading>
                                <Table.Heading>
                                  <p className="text-right">Subtotal</p>
                                </Table.Heading>
                                <Table.Heading></Table.Heading>
                              </Table.HeaderRow>
                            </Table.Header>
                            <Table.Body>
                              {salesOrderEditRequest?.so_before?.[0]?.oi_before?.map(
                                (orderDetail, key) => (
                                  <Table.BodyRow key={key}>
                                    <Table.Cell>
                                      <div className="flex gap-2 items-center">
                                        {orderDetail?.master_product
                                          ?.imageUrl && (
                                          <ImageWithPreview
                                            size="lg"
                                            src={`${process.env.REACT_APP_AWS_LINK_URL}${orderDetail?.master_product?.imageUrl}`}
                                          ></ImageWithPreview>
                                        )}
                                        <div className="w-4/5">
                                          {orderDetail?.master_product?.code} -{" "}
                                          {orderDetail?.master_product?.name}
                                        </div>
                                      </div>
                                    </Table.Cell>
                                    <Table.Cell className="w-24">
                                      {orderDetail?.master_size?.name}
                                    </Table.Cell>
                                    <Table.Cell className="max-w-xs">
                                      {orderDetail?.master_color?.name}
                                    </Table.Cell>
                                    <Table.Cell className="w-24">
                                      {orderDetail.qty}
                                    </Table.Cell>
                                    <Table.Cell className="w-48">
                                      <div className="text-right">
                                        <Typography.Currency
                                          number={orderDetail.unit_price}
                                        />
                                      </div>
                                    </Table.Cell>
                                    <Table.Cell className="w-32">
                                      <div className="text-right">
                                        <Typography.Currency
                                          number={orderDetail.discount_reseller}
                                        />
                                      </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <div className="text-right">
                                        <Typography.Currency
                                          number={
                                            orderDetail.qty *
                                            (orderDetail.unit_price -
                                              orderDetail.discount_reseller)
                                          }
                                        />
                                      </div>
                                    </Table.Cell>
                                    <Table.Cell></Table.Cell>
                                  </Table.BodyRow>
                                )
                              )}
                            </Table.Body>
                          </Table.Wrapper>
                          <FormSummary>
                            <div className="flex-grow w-full md:max-w-lg order-last md:order-first">
                              <InputMolecule
                                label="Catatan"
                                type="text"
                                name="note_order"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  salesOrderEditRequest?.so_before?.[0]
                                    ?.note_order
                                }
                                errorMessage={
                                  errors.note_order &&
                                  touched.note_order &&
                                  errors.note_order
                                }
                                disabled={true}
                              />
                            </div>
                            <div className="h-full grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right">
                              <div className="text-xs uppercase text-gray-600 text-right font-light">
                                Subtotal:{" "}
                              </div>
                              <div className="pr-7">
                                <Typography.Currency
                                  number={localHelpers.subtotal(
                                    salesOrderEditRequest?.so_before?.[0]
                                      ?.oi_before
                                  )}
                                />
                              </div>

                              <div className="text-xs uppercase text-gray-600 text-right font-light">
                                Ongkir ({" "}
                                {
                                  <Typography.Currency
                                    number={localHelpers.totalWeight(
                                      salesOrderEditRequest?.so_before?.[0]
                                        ?.oi_before
                                    )}
                                    fontSize="xs"
                                  />
                                }{" "}
                                gram) :{" "}
                              </div>
                              <div className="flex items-center w-full justify-end">
                                {/* <Typography.Currency number={delivery_fee} /> */}
                                <div className="pr-7">
                                  <Typography.Currency
                                    number={delivery_fee_before}
                                  />
                                </div>
                                {/* <IconButton
                        icon={ArrowPathIcon}
                        onClick={(e) => {
                          getDeliveryFee(
                            values.address_id,
                            values.items,
                            selectedCourier
                          );
                          // setNeedUpdateDeliveryFee(false);
                        }}
                        disabled={!needUpdateDeliveryFee}
                        color={needUpdateDeliveryFee ? "accent" : "secondary"}
                      /> */}
                              </div>
                              <div className="text-xs uppercase text-gray-600 text-right font-bold">
                                Total:{" "}
                              </div>
                              <div className="pr-7">
                                <Typography.Currency
                                  number={
                                    +localHelpers.subtotal(
                                      salesOrderEditRequest?.so_before?.[0]
                                        ?.oi_before
                                    ) + Number(delivery_fee_before)
                                  }
                                />
                              </div>
                            </div>
                          </FormSummary>
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  code: "after",
                  title: "AFTER UPDATE",
                  element: (
                    <div className="mt-2">
                      <FormHeader>
                        {/* <SelectSearchMolecule
                          label="Customer"
                          name="customer_id"
                          options={customers?.map((customer) => ({
                            value: customer.id,
                            label:
                              customer.firstName +
                              " " +
                              customer.middleName +
                              " " +
                              customer.lastName,
                          }))}
                          onChange={(e) => {
                            handleChange(e);
                            getAddress(e.target.value);
                            setNeedUpdateDeliveryFee(true);
                          }}
                          onBlur={handleBlur}
                          value={values.customer_id}
                          errorMessage={
                            errors.customer_id &&
                            touched.customer_id &&
                            errors.customer_id
                          }
                          disabled={
                            salesOrderEditRequest?.so_before?.[0].invoice_id
                          }
                        /> */}
                        <div>
                          <InputLabel>Customer</InputLabel>
                          <p className="px-2 py-2">
                            {salesOrderEditRequest?.so_before?.[0].MCustomer
                              ?.firstName +
                              " " +
                              salesOrderEditRequest?.so_before?.[0].MCustomer
                                ?.middleName +
                              " " +
                              salesOrderEditRequest?.so_before?.[0].MCustomer
                                ?.lastName}
                          </p>
                        </div>
                        <SelectMolecule
                          label="Alamat Kirim"
                          name="address_id"
                          options={addresses}
                          onChange={(e) => {
                            handleChange(e);
                            setNeedUpdateDeliveryFee(true);
                          }}
                          onBlur={handleBlur}
                          value={values.address_id}
                          errorMessage={
                            errors.address_id &&
                            touched.address_id &&
                            errors.address_id
                          }
                        />
                        <SelectMolecule
                          label="Kurir"
                          name="courier_package"
                          options={[
                            {
                              value: "JAGOPACK",
                              label: "JAGOPACK",
                            },
                            {
                              value: "REGPACK",
                              label: "REGPACK",
                            },
                            {
                              value: "BIGPACK",
                              label: "BIGPACK",
                            },
                            {
                              value: "BOSSPACK",
                              label: "BOSSPACK",
                            },
                          ]}
                          value={values.courier_package}
                          onChange={(e) => {
                            handleChange(e);
                            getDeliveryFee(
                              values.address_id,
                              values.items,
                              e.target.value
                            );
                            setSelectedCourier(e.target.value);
                            setNeedUpdateDeliveryFee(true);
                          }}
                        />
                      </FormHeader>
                      <div className="py-2 overflow-y-visible">
                        <div className="w-full">
                          <FieldArray name="items">
                            {(arrayHelpers) => (
                              <div className="">
                                <Table.Wrapper asIndex={false} overflow={false}>
                                  <Table.Header>
                                    <Table.HeaderRow>
                                      <Table.Heading>Product</Table.Heading>
                                      <Table.Heading>Size</Table.Heading>
                                      <Table.Heading>Warna</Table.Heading>
                                      {/* <Table.Heading>Catatan</Table.Heading> */}
                                      <Table.Heading>Qty</Table.Heading>
                                      <Table.Heading>
                                        <p className="text-right">
                                          Harga Satuan
                                        </p>
                                      </Table.Heading>
                                      <Table.Heading>
                                        <p className="text-right">Diskon</p>
                                      </Table.Heading>
                                      <Table.Heading>
                                        <p className="text-right">Subtotal</p>
                                      </Table.Heading>
                                      <Table.Heading></Table.Heading>
                                    </Table.HeaderRow>
                                  </Table.Header>
                                  <Table.Body>
                                    {values.items?.map((orderDetail, key) => (
                                      <Table.BodyRow key={key}>
                                        <Table.Cell>
                                          <div className="flex gap-2 items-center">
                                            {orderDetail?.imageUrl && (
                                              <ImageWithPreview
                                                size="lg"
                                                src={`${process.env.REACT_APP_AWS_LINK_URL}${orderDetail?.imageUrl}`}
                                              ></ImageWithPreview>
                                            )}
                                            <div className="w-4/5">
                                              <ProductSelectSearchMolecule
                                                hideLabel={true}
                                                name={`items[${key}].product_id`}
                                                onChange={(e) => {
                                                  // reset varian size dan color
                                                  values.items[
                                                    `${key}`
                                                  ].color_variant_id = null;
                                                  values.items[
                                                    `${key}`
                                                  ].size_variant_id = null;

                                                  handleChange(e);

                                                  // karena produk diganti, harus kalkulasi ongkir ulang
                                                  setNeedUpdateDeliveryFee(
                                                    true
                                                  );
                                                }}
                                                onBlur={handleBlur}
                                                value={orderDetail.product_id}
                                                errorMessage={
                                                  getIn(
                                                    errors,
                                                    `items[${key}].product_id`
                                                  ) &&
                                                  getIn(
                                                    touched,
                                                    `items[${key}].product_id`
                                                  ) &&
                                                  getIn(
                                                    errors,
                                                    `items[${key}].product_id`
                                                  )
                                                }
                                                absolute={true}
                                                apiSearch={true}
                                                onProductSelected={(
                                                  selectedProduct
                                                ) => {
                                                  handleProductSelected(
                                                    selectedProduct,
                                                    key
                                                  );
                                                  updateProductPrice(
                                                    selectedProduct,
                                                    key,
                                                    values
                                                  );
                                                }}
                                                searchText={
                                                  selectedProducts[key]
                                                    ?.master_product?.code
                                                    ? selectedProducts[key]
                                                        ?.master_product?.code
                                                    : selectedProducts[key]
                                                        ?.code
                                                }
                                              />
                                            </div>
                                          </div>
                                        </Table.Cell>
                                        <Table.Cell className="w-24">
                                          <SelectMolecule
                                            hideLabel={true}
                                            name={`items[${key}].size_variant_id`}
                                            // options={orderDetail.size}
                                            options={getUniqueData(
                                              selectedProducts[key],
                                              values.items[`${key}`].product_id,
                                              "size",
                                              values.items[`${key}`]
                                                .color_variant_id
                                            )}
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            value={orderDetail.size_variant_id}
                                            errorMessage={
                                              getIn(
                                                errors,
                                                `items[${key}].size_variant_id`
                                              ) &&
                                              getIn(
                                                touched,
                                                `items[${key}].size_variant_id`
                                              ) &&
                                              getIn(
                                                errors,
                                                `items[${key}].size_variant_id`
                                              )
                                            }
                                            absolute={true}
                                          />
                                        </Table.Cell>
                                        <Table.Cell className="max-w-xs">
                                          <SelectMolecule
                                            hideLabel={true}
                                            name={`items[${key}].color_variant_id`}
                                            // options={orderDetail.color}
                                            options={getUniqueData(
                                              selectedProducts[key],
                                              values.items[`${key}`].product_id,
                                              "color",
                                              values.items[`${key}`]
                                                .size_variant_id
                                            )}
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            value={orderDetail.color_variant_id}
                                            errorMessage={
                                              getIn(
                                                errors,
                                                `items[${key}].color_variant_id`
                                              ) &&
                                              getIn(
                                                touched,
                                                `items[${key}].color_variant_id`
                                              ) &&
                                              getIn(
                                                errors,
                                                `items[${key}].color_variant_id`
                                              )
                                            }
                                            absolute={true}
                                          />
                                        </Table.Cell>
                                        {/* <Table.Cell>
                                <InputMolecule
                                  hideLabel={true}
                                  type="text"
                                  name={`items[${key}].notes`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.notes}
                                  errorMessage={
                                    getIn(errors, `items[${key}].notes`) &&
                                    getIn(touched, `items[${key}].notes`) &&
                                    getIn(errors, `items[${key}].notes`)
                                  }
                                />
                              </Table.Cell> */}
                                        <Table.Cell className="w-24">
                                          <InputMolecule
                                            hideLabel={true}
                                            type="number"
                                            name={`items[${key}].qty`}
                                            onChange={(e) => {
                                              handleChange(e);
                                              setNeedUpdateDeliveryFee(true);
                                            }}
                                            onBlur={handleBlur}
                                            value={orderDetail.qty}
                                            errorMessage={
                                              getIn(
                                                errors,
                                                `items[${key}].qty`
                                              ) &&
                                              getIn(
                                                touched,
                                                `items[${key}].qty`
                                              ) &&
                                              getIn(errors, `items[${key}].qty`)
                                            }
                                            align="right"
                                          />
                                        </Table.Cell>
                                        <Table.Cell className="w-48">
                                          <InputMolecule
                                            hideLabel={true}
                                            type="number"
                                            name={`items[${key}].unit_price`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={orderDetail.unit_price}
                                            errorMessage={
                                              getIn(
                                                errors,
                                                `items[${key}].unit_price`
                                              ) &&
                                              getIn(
                                                touched,
                                                `items[${key}].unit_price`
                                              ) &&
                                              getIn(
                                                errors,
                                                `items[${key}].unit_price`
                                              )
                                            }
                                            disabled={true}
                                            align="right"
                                          />
                                        </Table.Cell>
                                        <Table.Cell className="w-32">
                                          <InputMolecule
                                            hideLabel={true}
                                            type="text"
                                            name={`items[${key}].discount_reseller`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              orderDetail.discount_reseller
                                            }
                                            errorMessage={
                                              getIn(
                                                errors,
                                                `items[${key}].discount_reseller`
                                              ) &&
                                              getIn(
                                                touched,
                                                `items[${key}].discount_reseller`
                                              ) &&
                                              getIn(
                                                errors,
                                                `items[${key}].discount_reseller`
                                              )
                                            }
                                            align="right"
                                          />
                                        </Table.Cell>
                                        <Table.Cell>
                                          <div className="text-right">
                                            <Typography.Currency
                                              number={
                                                orderDetail.qty *
                                                (orderDetail.unit_price -
                                                  orderDetail.discount_reseller)
                                              }
                                            />
                                          </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                          <IconButton
                                            icon={TrashIcon}
                                            onClick={(e) => {
                                              const newArrayItems =
                                                values.items.filter(
                                                  (i) =>
                                                    i.id !==
                                                    values.items[key].id
                                                );

                                              setInitialValues({
                                                ...values,
                                                items: newArrayItems,
                                              });

                                              setSelectedProducts(
                                                (prevSelectedProducts) => {
                                                  let updatedSelectedProducts =
                                                    [];
                                                  updatedSelectedProducts =
                                                    prevSelectedProducts.filter(
                                                      (item, index) =>
                                                        index !== key
                                                    );
                                                  return updatedSelectedProducts;
                                                }
                                              );
                                            }}
                                          />
                                        </Table.Cell>
                                      </Table.BodyRow>
                                    ))}
                                  </Table.Body>
                                </Table.Wrapper>
                                <div>
                                  <div className="">
                                    <DottedOutlineButton
                                      type="button"
                                      onClick={(e) =>
                                        arrayHelpers.push({
                                          product_id: null,
                                          size_variant_id: null,
                                          color_variant_id: null,
                                          qty: 1,
                                          unitPrice: 0,
                                          discount_reseller: 0,
                                          weight: 0,
                                        })
                                      }
                                    >
                                      Tambah Item
                                    </DottedOutlineButton>
                                  </div>
                                </div>
                              </div>
                            )}
                          </FieldArray>
                          <FormSummary>
                            <div className="flex-grow w-full md:max-w-lg order-last md:order-first">
                              <InputMolecule
                                label="Catatan"
                                type="text"
                                name="note_order"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.note_order}
                                errorMessage={
                                  errors.note_order &&
                                  touched.note_order &&
                                  errors.note_order
                                }
                              />
                            </div>
                            <div className="h-full grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right">
                              <div className="text-xs uppercase text-gray-600 text-right font-light">
                                Subtotal:{" "}
                              </div>
                              <div className="pr-7">
                                <Typography.Currency
                                  number={localHelpers.subtotal(values?.items)}
                                />
                              </div>

                              <div className="text-xs uppercase text-gray-600 text-right font-light">
                                Ongkir ({" "}
                                {
                                  <Typography.Currency
                                    number={localHelpers.totalWeight(
                                      values?.items
                                    )}
                                    fontSize="xs"
                                  />
                                }{" "}
                                gram) :{" "}
                              </div>
                              <div className="flex items-center w-full justify-end">
                                {/* <Typography.Currency number={delivery_fee} /> */}
                                <InputMolecule
                                  hideLabel={true}
                                  type="text"
                                  name={`delivery_fee`}
                                  onChange={(e) => {
                                    setDeliveryFee(
                                      Math.abs(Number(e.target.value))
                                    );
                                    setNeedUpdateDeliveryFee(false);
                                  }}
                                  onBlur={handleBlur}
                                  value={delivery_fee}
                                  errorMessage={
                                    getIn(errors, `delivery_fee`) &&
                                    getIn(touched, `delivery_fee`) &&
                                    getIn(errors, `delivery_fee`)
                                  }
                                  align="right"
                                />
                                <IconButton
                                  icon={ArrowPathIcon}
                                  onClick={(e) => {
                                    getDeliveryFee(
                                      values.address_id,
                                      values.items,
                                      selectedCourier
                                    );
                                    // setNeedUpdateDeliveryFee(false);
                                  }}
                                  disabled={!needUpdateDeliveryFee}
                                  color={
                                    needUpdateDeliveryFee
                                      ? "accent"
                                      : "secondary"
                                  }
                                />
                              </div>
                              <div className="text-xs uppercase text-gray-600 text-right font-bold">
                                Total:{" "}
                              </div>
                              <div className="pr-7">
                                <Typography.Currency
                                  number={
                                    +localHelpers.subtotal(values?.items) +
                                    Number(delivery_fee)
                                  }
                                />
                              </div>
                            </div>
                          </FormSummary>
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
            />

            <div className="fixed bottom-0 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end gap-2 border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
              <Button
                onClick={() => runValidations(values, setFieldValue, 3)}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Tolak"}
              </Button>
              <Button
                onClick={() => runValidations(values, setFieldValue, 2)}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Terima"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

export default UpdateSalesOrderEditRequestPage;
