import axios from "../axios";
import h from "../helpers";
import { clientIp } from "../api-address";

const supplier = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("suppliers"), {
      params: { ...params, order: "firstName", sort: "asc" },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("suppliers/" + id));
    return data;
  },
  store: async (
    firstName,
    middleName,
    lastName,
    nickName,
    first_bank,
    second_bank,
    third_bank,
    email,
    phone,
    supplier_address
  ) => {
    const { data } = await axios.post(h.baseUrl("suppliers"), {
      firstName,
      middleName,
      lastName,
      nickName,
      first_bank,
      second_bank,
      third_bank,
      email,
      phone,
      supplier_address,
      ip_address: await clientIp(),
    });
    return data;
  },
  update: async (
    id,
    firstName,
    middleName,
    lastName,
    nickName,
    first_bank,
    second_bank,
    third_bank,
    email,
    phone,
    supplier_address
  ) => {
    const { data } = await axios.put(h.baseUrl("suppliers/" + id), {
      firstName,
      middleName,
      lastName,
      nickName,
      first_bank,
      second_bank,
      third_bank,
      email,
      phone,
      supplier_address,
      ip_address: await clientIp(),
    });
    return data;
  },
  delete: async (id) => {
    const ip = await clientIp();
    const { data } = await axios.delete(
      h.baseUrl("suppliers/" + id + `?ip_address=${ip}`)
    );
    return data;
  },
};

export default supplier;
