import axios from "../axios";
import h from "../helpers";

const orderItem = {
  customerOrderQueue: async (params) => {
    const { data } = await axios.get(h.baseUrl("order-items/customer/queue"), {
      params: { ...params },
    });
    return data;
  },
  customerOrderList: async (params) => {
    const { data } = await axios.get(h.baseUrl("order-items/customer/list"), {
      params: { ...params },
    });
    return data;
  },
  orderPackingUpdate: async (id, status_packing) => {
    const { data } = await axios.put(h.baseUrl("order-items/" + id), {
      status_packing,
    });
    return data;
  },
};

export default orderItem;
