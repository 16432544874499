import clsx from "clsx";

function ControlledTab({
  tabs = [],
  withPadding = true,
  opened = "",
  onClickTab = () => {},
  location,
  hideAtMobile,
  position = "",
  display = "",
}) {
  function tabIsOpened(code) {
    return opened == code;
  }

  return (
    <>
      <div className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500">
        <div className={clsx(position == "bottom" ? " max-w-screen-sm mx-auto fixed bottom-0 left-0 right-0 z-30 flex items-center justify-evenly w-full h-16 flex-shrink-0 border-t border-x border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800 md:bg-white md:dark:bg-gray-800" : "")}>
          <ul className={clsx("-mb-px flex", position == "bottom" ? "w-full justify-evenly" : "")}>
            {tabs?.map((tab, i) => {
              return (
                <li
                  className={
                    clsx(
                      "hover:text-primary-500 grow border-b-2 text-center text-sm font-medium",
                      tabIsOpened(tab.code) ? "border-primary-500 text-primary-600" : ""
                    )
                  }
                  key={i}
                >
                  {/* <TabButton
                    active={tabIsOpened(i)}
                    onClick={(e) => setOpened(i)}
                  >
                    {tab.title} <RedDotBadge show={tab.hasErrors} />
                  </TabButton> */}
                  <button
                    onClick={e => {
                      e.target.tabCode = tab.code;
                      onClickTab(e);
                    }}
                    type="button"
                    className="w-full h-full flex justify-center py-2 px-1"
                  >

                    {display == "icon" && tab.icon !== null ? <div className="h-10 w-10">{tab.icon}</div> : tab.title}
                    {/* {tab.title}  */}
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {tabs?.map((tab, i) => {
        if (tabIsOpened(tab.code)) {
          return (
            <div
              className={
                clsx(
                  "w-full overflow-y-auto pb-8 print:h-fit print:overflow-hidden",
                  withPadding ? "p-4" : "",
                  position == "bottom" ? "main-content-min-height" : "main_content_height"
                )
              }
              key={i}
            >
              {tab.element}
            </div>
          )
        }
      })}
    </>
  )
}

export default ControlledTab;
