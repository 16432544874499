import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import { FormHeader, FormSummary } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import api from "app/integration/api";
import {
  useCollector,
  useFetcher,
  useUpdateForm,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik } from "formik";
import { DateTime } from "luxon";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import style from "style/height.module.css";
import { filter as _filter, isNull } from "underscore";
import * as Yup from "yup";
import str from "lib/str";
import ImageWithPreview from "app/components/molecules/imageWithPreview";

const breadcrumbItems = [
  { label: "Sales", href: "/invoice" },
  { label: "Invoice", href: "/invoice" },
  { label: "Detail Invoice", href: "#" },
];

function InvoiceDetailPage() {
  const navigate = useNavigate();
  let { id } = useParams();

  const { openModal, setPayloads } = useApplicationModals();

  const handleSuccess = () => {
    navigate("/invoice");
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        customer_id: null,
        total_amount: 0,
        is_use_credit: false,
        isUsePlafon: false,
        invoice_date: "",
        due_date: "",
        credit_amount: 0,
        status: 0,
        note: "",
        items: [],
        salesOrders: [],
        customerPhone: "",
        inv_code: "",
        customerName: "",
      },
      validationSchema: Yup.object().shape({
        customer_id: Yup.number().required("Customer harus diisi"),
        total_amount: Yup.number(),
        invoice_date: Yup.date().required("Tanggal Invoice harus diisi"),
        due_date: Yup.date().required("Tanggal Jatuh Tempo harus diisi"),
        credit_amount: Yup.number(),
      }),
      onSubmit: async (values) => {
        await api.invoice.store(
          values.customer_id,
          values.total_amount,
          values.is_use_credit,
          values.isUsePlafon,
          values.invoice_date,
          values.due_date,
          values.credit_amount,
          values.status,
          values.note,
          values.items,
          values.orders,
          values.customerPhone,
          values.inv_code,
          values.customerName
        );
      },
      onSuccess: handleSuccess,
    });

  const [invoice, setInvoice, loader, fetch] = useFetcher({
    modelName: "invoice",
    payload: { id },
  });
  const [salesOrders, setSalesOrder] = useState([]);

  useEffect(() => {
    setSalesOrder(invoice?.SalesOrders);
    setInitialValues({
      id: invoice.id,
      customer_id: invoice.customer_id,
      total_amount: invoice.total_amount,
      is_use_credit: invoice.is_use_credit,
      isUsePlafon: invoice.is_use_plafon,
      invoice_date: invoice.createdAt,
      due_date: invoice.due_date,
      credit_amount: invoice.credit_amount,
      status: invoice.status,
      note: invoice.note,
      items: invoice?.SalesOrders?.map((order) => order.id),
      customerPhone: invoice?.inv_customer?.phone,
      inv_code: invoice?.inv_code,
      customerName:
        invoice?.inv_customer?.firstName +
        (invoice?.inv_customer?.middleName?.length > 0
          ? " " + invoice?.inv_customer?.middleName
          : "") +
        (invoice?.inv_customer?.lastName?.length > 0
          ? " " + invoice?.inv_customer?.lastName
          : ""),
      sales_orders: invoice?.SalesOrders,
      total_payment: invoice?.total_payment,
    });
  }, [invoice]);

  // const [customers] = useCollector({ modelName: "customer" });

  const localHelpers = {
    subtotal: (customer_id) => {
      console.log("initial sales", salesOrders);
      return _filter(salesOrders, {
        customer_id: customer_id,
        invoice_id: invoice.id,
      })
        .map((salesOrder) => {
          return salesOrder?.OrderItems.map(
            (orderDetail) =>
              orderDetail.qty *
              (orderDetail.unit_price - orderDetail.discount_reseller)
          ).reduce((partialSum, a) => partialSum + a, 0);
        })
        .reduce((partialSum, a) => partialSum + a, 0);
    },

    totalOngkir: (customer_id) => {
      console.log("initial sales", salesOrders);
      return _filter(salesOrders, {
        customer_id: customer_id,
        invoice_id: invoice.id,
      }).reduce((a, b) => {
        return Number(a) + Number(b.delivery_fee);
      }, 0);
    },

    total: (customer_id) => {
      return (
        +localHelpers.subtotal(customer_id) +
        localHelpers.totalOngkir(customer_id)
      );
    },

    payment: (payments) => {
      var total = 0;
      payments?.map((payment, key) => (total += Number(payment.total_payment)));
      return total;
    },
  };

  function convertPaymentStatus(status) {
    switch (status) {
      case 1:
        return "Unpaid";
      case 2:
        return "Partial";
      case 3:
        return "Lunas";
      case 4:
        return "Cancel";
      default:
        break;
    }
  }

  function getOrderSummary() {
    var txt = "Halo ";
    // var customer = customers?.find((customer) => {
    //   return customer.id === initialValues?.customer_id;
    // });
    txt += initialValues.customerName + ",%0a";
    txt += "Berikut adalah invoice anda:%0a";
    txt += "Nomor invoice: *" + initialValues?.inv_code + "*%0a%0a";

    initialValues?.orders?.map((salesOrder, key) => {
      txt +=
        "Order " +
        // salesOrder.order_code +
        // ": " +
        str.standardDay(salesOrder.delivery_date, "id") +
        " " +
        str.shortDate(salesOrder.delivery_date, "id") +
        "%0a";
      salesOrder.OrderItems.map((orderItem, nestedKey) => {
        txt +=
          "Item: " +
          orderItem?.Product?.ProductCategory?.name +
          ": " +
          orderItem?.Product?.name +
          " (" +
          orderItem.qty +
          ")" +
          // " @" +
          // str.currency(orderItem.unit_price) +
          "";
        if (orderItem.variants.length > 0) {
          txt +=
            "%0aReq: " +
            orderItem.variants.map((item) => item.name).join(", ") +
            "%0a";
        }
        txt += "%0a";
        // orderItem.variants.map((variantItem, nestedNestedKey) => {
        // txt +=
        //   "Request: " +
        //   variantItem.name + "%0a";
        // ": (" +
        // orderItem.qty + ")%0a";
        // " @" +
        // str.currency(variantItem.price) +
        // ")%0a"
        // });
      });
      txt += "%0a";
    });
    // txt +=
    //   "%0aSubtotal: " +
    //   str.currency(localHelpers.subtotal(initialValues.customer_id)) +
    //   "%0a";
    // txt +=
    //   "Ongkir: " +
    //   str.currency(localHelpers.shipmentCost(initialValues.customer_id)) +
    //   "%0a";
    // txt +=
    //   "Diskon Ongkir: -" +
    //   str.currency(
    //     localHelpers.shipmentCostDiscount(initialValues.customer_id)
    //   ) +
    //   "%0a";
    // txt +=
    //   "%0aTotal: " +
    //   str.currency(localHelpers.total(initialValues.customer_id)) +
    //   "%0a";
    if (initialValues.credit_amount > 0) {
      txt +=
        "Kredit Digunakan: " +
        str.currency(initialValues.credit_amount) +
        "%0a";
    }
    if (invoice.Payments?.length > 0) {
      txt +=
        "Pembayaran: -" +
        str.currency(localHelpers.payment(invoice.Payments)) +
        "%0a";
    }
    txt +=
      "*Sisa Tagihan: " +
      str.currency(
        localHelpers.total(initialValues.customer_id) -
          initialValues.credit_amount -
          localHelpers.payment(invoice.Payments)
      ) +
      "*%0a";
    txt +=
      "%0a%0aUntuk melakukan pembayaran, harap transfer ke:%0aBank: *";
    txt += process.env.REACT_APP_REK_BANK;
    txt += "*%0aAtas nama: *"; 
    txt += process.env.REACT_APP_REK_NAME;
    txt += "*%0aNomor rekening: *";
    txt += process.env.REACT_APP_REK_NO;
    txt += "*%0aSetelah melakukan pembayaran, *harap mengirimkan bukti pembayaran melalui WhatsApp*. Terima kasih!";

    if (txt.length > 0 && !isNull(document.getElementById("txtInvoice"))) {
      document.getElementById("txtInvoice").innerHTML = txt?.replaceAll(
        "%0a",
        "\n"
      );
    }
    return txt;
  }

  function copyToClipboard() {
    // document.getElementById("txtInvoice").innerHTML = getOrderSummary();

    // Get the text field
    var copyText = document.getElementById("txtInvoice").innerHTML;
    var elem = document.createElement("textarea");
    document.body.appendChild(elem);
    elem.value = copyText;

    // Select the text field
    elem.select();
    elem.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(elem.value);

    // Alert the copied text
    // alert("Copied the text: " + elem.value);
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <MainLayout
      activeSidebarNavigation="invoice"
      breadcrumbItems={breadcrumbItems}
      pageTitle={initialValues?.inv_code}
      headingButtons={[
        {
          label: "Register Payment",
          type: "menuButton",
          onClick: (e) => {
            setPayloads("payment.create", { invoice_id: id });
            openModal("payment.create");
          },
          items: [
            {
              label: "Share via WhatsApp",
              href:
                "https://wa.me/" +
                +initialValues?.customerPhone +
                "?text=" +
                getOrderSummary(),
            },
            {
              label: "View Invoice",
              onClick: (e) => {
                setPayloads("invoice.view", {
                  data: initialValues,
                });
                openModal("invoice.view");
              },
            },
          ],
        },
      ]}
    >
      <AppModals
        items={["payment.create"]}
        onSuccess={{
          "payment.create": () => fetch(),
        }}
      />
      <AppModals
        items={["invoice.view"]}
        onSuccess={{
          "invoice.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
            ref={componentRef}
          >
            <FormHeader color="white" background="white" appearance="none">
              <div className="col-span-3 md:col-span-2 grid grid-cols-3">
                <span className="whitespace-nowrap text-gray-500 text-sm">
                  Nama:
                </span>
                <p className="whitespace-wrap text-sm col-span-2">
                  {values.customerName}
                  {invoice?.inv_customer?.deletedAt !== null ? (
                    <span className="text-danger-400 font-light text-xs">
                      {" "}
                      [deleted customer]
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                <span className="whitespace-nowrap text-gray-500 text-sm">
                  Telepon:
                </span>
                <p className="whitespace-nowrap text-sm col-span-2">
                  {values.customerPhone}
                </p>
                <span className="whitespace-nowrap text-gray-500 text-sm">
                  Tanggal Invoice:
                </span>
                <Typography.Date
                  date={values.invoice_date}
                  size="sm"
                ></Typography.Date>
              </div>
              <span className="absolute right-2 top-1 whitespace-nowrap text-right col-span-2 text-sm font-medium uppercase">
                {invoice.status === 3 ? (
                  <Badge color="green" size="lg">
                    {convertPaymentStatus(invoice.status)}
                  </Badge>
                ) : (
                  <></>
                )}
                {invoice.status === 2 ? (
                  <Badge color="yellow" size="lg">
                    {convertPaymentStatus(invoice.status)}
                  </Badge>
                ) : (
                  <></>
                )}
                {invoice.status === 1 ? (
                  <Badge color="red" size="lg">
                    {convertPaymentStatus(invoice.status)}
                  </Badge>
                ) : (
                  <></>
                )}
              </span>
              {/* <InputMolecule
                label="Tanggal Invoice"
                type="date"
                name="invoice_date"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.invoice_date}
                errorMessage={
                  errors.invoice_date &&
                  touched.invoice_date &&
                  errors.invoice_date
                }
              /> */}
              {/* <InputMolecule
                label="Tanggal Jatuh Tempo"
                type="date"
                name="due_date"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.due_date}
                errorMessage={
                  errors.due_date && touched.due_date && errors.due_date
                }
              /> */}
              {/* <InputMolecule
                label="Catatan"
                type="text"
                name="note"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.note}
                errorMessage={errors.note && touched.note && errors.note}
              /> */}
            </FormHeader>
            <div className="py-2 overflow-y-visible col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
              <div className="w-full">
                {/* <p>{initialValues.items}</p> */}
                <FieldArray name="orderDetails">
                  {(arrayHelpers) => (
                    <div className="table-content-height overflow-y-auto">
                      <Table.Wrapper asIndex={false}>
                        <Table.Header>
                          <Table.HeaderRow>
                            <Table.Heading>Order</Table.Heading>
                            <Table.Heading className="text-right">
                              Harga
                            </Table.Heading>
                            <Table.Heading className="text-right">
                              Diskon
                            </Table.Heading>
                            <Table.Heading className="text-right">
                              Qty
                            </Table.Heading>
                            <Table.Heading className="text-right">
                              Subtotal
                            </Table.Heading>
                            <Table.Heading className="text-right">
                              Ongkir
                            </Table.Heading>
                            {/* <Table.Heading className="text-right">
                              Diskon Ongkir
                            </Table.Heading> */}
                            {/* <Table.Heading className="text-right">
                              Parkir
                            </Table.Heading> */}
                          </Table.HeaderRow>
                        </Table.Header>
                        <Table.Body>
                          {salesOrders?.map((salesOrder, key) => (
                            <Fragment key={key}>
                              <Table.BodyRow>
                                <Table.Cell
                                  size="sm"
                                  fontWeight="semibold"
                                  background={100}
                                >
                                  <div className="flex gap-4 items-center">
                                    {salesOrder.order_code}
                                    {/* <Typography.Date
                                      date={salesOrder.delivery_date}
                                      size="sm"
                                    /> */}
                                    {/* <Badge
                                      color={
                                        salesOrder.is_valid_order
                                          ? "green"
                                          : "red"
                                      }
                                    >
                                      {salesOrder.is_valid_order
                                        ? "Valid"
                                        : DateTime.fromISO(
                                            salesOrder.delivery_date
                                          )
                                            .set({
                                              hour: 0,
                                              minute: 0,
                                              second: 0,
                                              millisecond: 0,
                                            })
                                            .diff(
                                              DateTime.now().set({
                                                hour: 0,
                                                minute: 0,
                                                second: 0,
                                                millisecond: 0,
                                              }),
                                              ["days"]
                                            )
                                            .toObject().days < 0
                                        ? "Invalid / Expired"
                                        : "Invalid"}
                                    </Badge> */}
                                  </div>
                                </Table.Cell>
                                <Table.Cell
                                  size="sm"
                                  background={100}
                                ></Table.Cell>
                                <Table.Cell
                                  size="sm"
                                  background={100}
                                ></Table.Cell>
                                <Table.Cell
                                  size="sm"
                                  background={100}
                                ></Table.Cell>
                                <Table.Cell
                                  size="sm"
                                  background={100}
                                ></Table.Cell>
                                <Table.Cell
                                  size="sm"
                                  background={100}
                                  className="text-right"
                                >
                                  <Typography.Currency
                                    number={salesOrder.delivery_fee}
                                  />
                                </Table.Cell>
                                {/* <Table.Cell
                                  size="sm"
                                  background={100}
                                  className="text-right"
                                >
                                  <Typography.Currency
                                    number={salesOrder.delivery_fee_discount}
                                  />
                                </Table.Cell> */}
                                {/* <Table.Cell
                                  size="sm"
                                  background={100}
                                  className="text-right"
                                >
                                  <Typography.Currency
                                    number={salesOrder.parking_fee}
                                  />
                                </Table.Cell> */}
                              </Table.BodyRow>
                              {salesOrder.OrderItems.map(
                                (orderItem, nestedKey) => (
                                  <Fragment key={nestedKey}>
                                    <Table.BodyRow>
                                      <Table.Cell size="sm" className="min-w-[250px]">
                                        <div className="flex gap-2 items-center">
                                          <div className="min-w-[50px]">
                                            {orderItem?.master_product
                                              ?.imageUrl && (
                                              <ImageWithPreview
                                                size="lg"
                                                src={`${process.env.REACT_APP_AWS_LINK_URL}${orderItem?.master_product?.imageUrl}`}
                                              ></ImageWithPreview>
                                            )}
                                          </div>
                                          <span className="">
                                            {orderItem?.master_product?.code} -
                                            {orderItem?.master_product?.name}
                                          </span>
                                          <div className="flex flex-wrap gap-1">
                                            <Badge color="gray">
                                              {orderItem?.master_size?.name}
                                            </Badge>
                                            <Badge color="gray">
                                              {orderItem?.master_color?.name}
                                            </Badge>
                                          </div>
                                        </div>
                                      </Table.Cell>
                                      <Table.Cell
                                        size="sm"
                                        className="text-right"
                                      >
                                        <Typography.Currency
                                          number={orderItem.unit_price}
                                        />
                                      </Table.Cell>
                                      <Table.Cell
                                        size="sm"
                                        className="text-right"
                                      >
                                        <Typography.Currency
                                          number={orderItem.discount_reseller}
                                        />
                                      </Table.Cell>
                                      <Table.Cell
                                        size="sm"
                                        className="text-right"
                                      >
                                        {orderItem.qty}
                                      </Table.Cell>
                                      <Table.Cell
                                        size="sm"
                                        className="text-right"
                                      >
                                        <Typography.Currency
                                          number={
                                            +orderItem.qty *
                                            (+orderItem.unit_price -
                                              orderItem.discount_reseller)
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.BodyRow>
                                  </Fragment>
                                )
                              )}
                            </Fragment>
                          ))}
                        </Table.Body>
                      </Table.Wrapper>
                    </div>
                  )}
                </FieldArray>
                <FormSummary big>
                  <div className="flex-grow w-full md:max-w-lg order-last md:order-first">
                    <h2 className="text-xs uppercase print:hidden">
                      Teks Invoice
                    </h2>
                    <p
                      className="h-24 overflow-y-auto border py-1 px-2 rounded-md bg-white print:hidden"
                      id="txtInvoice"
                    >
                      {" "}
                    </p>

                    <div className="flex justify-end pt-1 print:hidden">
                      <span
                        className="flex gap-1 cursor-pointer w-fit text-primary-600 text-xs"
                        onClick={copyToClipboard}
                      >
                        <ClipboardDocumentIcon className="h-4 w-4"></ClipboardDocumentIcon>
                        Copy
                      </span>
                    </div>
                  </div>
                  <div className="h-full grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right">
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Subtotal:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.subtotal(values.customer_id)}
                    />
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Ongkir:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.totalOngkir(values.customer_id)}
                    />
                    {/* <div className="col-span-2 my-3"></div> */}

                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Total:{" "}
                    </div>
                    <Typography.Currency
                      number={localHelpers.total(values.customer_id)}
                    />
                    <div className="col-span-2">
                      <hr />
                    </div>
                    <div className="flex justify-between">
                      {/* <input
                          readOnly={true}
                          disabled={true}
                          type="checkbox"
                          name="is_use_credit"
                          value={values.is_use_credit}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.checked) {
                              setFieldValue(
                                "credit_amount",
                                +_findWhere(customers, {
                                  id: values.customer_id,
                                })?.credit
                              );
                            } else {
                              setFieldValue("credit_amount", 0);
                            }
                          }}
                        /> */}
                      <div
                        className={clsx(
                          "text-xs uppercase text-right font-light",
                          values.is_use_credit
                            ? "text-gray-600"
                            : "text-gray-300"
                        )}
                      >
                        Kredit Customer:{" "}
                      </div>
                    </div>
                    <div
                      className={clsx(
                        values.is_use_credit ? "" : "text-gray-300"
                      )}
                    >
                      <Typography.Currency number={-values.credit_amount} />
                    </div>

                    {invoice?.total_payment > 0 ? (
                      <>
                        <div className="text-xs uppercase text-primary-600 text-right font-light">
                          Pembayaran:{" "}
                        </div>
                        <Typography.Currency number={invoice?.total_payment} />
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="text-sm uppercase text-gray-600 text-right font-bold">
                      Sisa Tagihan:{" "}
                    </div>
                    <Typography.Currency
                      number={
                        localHelpers.total(values.customer_id) -
                        +values.credit_amount -
                        invoice?.total_payment
                      }
                    />
                  </div>
                </FormSummary>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

export default InvoiceDetailPage;
