import { useEffect, useState } from "react";
import { SelectSearchMolecule } from ".";
import { usePaginatedCollector } from "app/integration/common/hooks";

function ProductSelectSearchMolecule({
  label,
  name,
  placeholder,
  value,
  onChange = () => {},
  onSearch = () => {},
  apiSearch = false,
  errorMessage,
  hideLabel = false,
  absolute = true,
  required = false,
  disabled = false,
  onProductSelected,
  searchText = "",
}) {
  const [localValue, setLocalValue] = useState(value);
  const localOnChange = (event) => {
    setLocalValue(event.target.value);
    onChange(event);

    // Call the callback function to pass the selected product to the parent
    const selectedProduct = products.find(
      (product) => product.id === event.target.value
    );
    onProductSelected(selectedProduct);
  };

  const localOnSearch = (searchValue) => {
    setProductParams({
      ...productParams,
      product_search: searchValue,
    });
    onSearch(searchValue);
  };

  const [productParams, setProductParams] = useState({
    search: "",
    product_search: searchText,
    order: "code",
    sort: "asc",
    status: null,
    customer_id: null,
    page: 1,
    limit: 50,
  });

  const [
    products,
    setProducts,
    productsLoader,
    collectProducts,
    productPaginationProps,
  ] = usePaginatedCollector({ modelName: "product", params: productParams });

  useEffect(() => {
    collectProducts(productParams);
  }, [productParams]);

  useEffect(() => {
    // Call the callback function to pass the selected product to the parent
    const selectedProduct = products.find(
      (product) => product.id === localValue
    );
    onProductSelected(selectedProduct);
  }, [products]);

  return (
    <SelectSearchMolecule
      label={label}
      placeholder={placeholder}
      name={name}
      options={products.map((product) => ({
        value: product.id,
        label: product.code + ": " + product.name,
      }))}
      onChange={localOnChange}
      value={localValue}
      absolute={absolute}
      disabled={disabled}
      onSearch={localOnSearch}
      apiSearch={apiSearch}
      errorMessage={errorMessage}
      required={required}
      hideLabel={hideLabel}
    />
  );
}

export default ProductSelectSearchMolecule;
