import LoginPage from "app/pages/login";
import LogoutPage from "app/pages/logout";
import UserProfilePage from "app/pages/user";
import ColorVariant from "app/pages/colorVariant";
import SizeVariantPage from "app/pages/sizeVariant";
import ProductVariantPage from "app/pages/productVariant";
import ProductPage from "app/pages/product";
import ProductDetail from "app/pages/product/detail";
import CategoryPage from "app/pages/category";
import CustomerPage from "app/pages/customer";
import CustomerDetailSubPage from "app/pages/customer/detail";
import SupplierPage from "app/pages/supplier";
import SalesOrderPage from "app/pages/salesOrder";
import CreateSalesOrderPage from "app/pages/salesOrder/create";
import UpdateSalesOrderPage from "app/pages/salesOrder/update";
import CustomerOrderQueuePage from "app/pages/salesOrder/customerOrderQueue";
import CustomerOrderList from "app/pages/salesOrder/customerOrderList";
import CustomerOrderListGrid from "app/pages/salesOrder/customerOrderListGrid";
import SalesOrderEditRequestPage from "app/pages/salesOrderEditRequest";
import CreateSalesOrderEditRequestPage from "app/pages/salesOrderEditRequest/create";
import UpdateSalesOrderEditRequestPage from "app/pages/salesOrderEditRequest/update";
import InvoicePage from "app/pages/invoice";
import CreateInvoicePage from "app/pages/invoice/create";
import InvoiceDetailPage from "app/pages/invoice/detail";
import PurchaseOrderPage from "app/pages/purchaseOrder";
import CreatePurchaseOrderPage from "app/pages/purchaseOrder/create";
import UpdatePurchaseOrder from "app/pages/purchaseOrder/update";
import ReceiveOrderPage from "app/pages/receiveOrder";
import UpdateReceiveOrder from "app/pages/receiveOrder/update";
import DeliveryOrderPage from "app/pages/deliveryOrder";
import PackingOrderPage from "app/pages/deliveryOrder/packing";
import DeliveryPage from "app/pages/deliveryOrder/delivery";
import OrderBelumDibeliPage from "app/pages/deliveryOrder/orderBelumDibeli";
import DailyOmzet from "app/pages/report/dailyOmzet";
import TopCustomer from "app/pages/report/topCustomer";
import TopSales from "app/pages/report/topSales";
import TopProduct from "app/pages/report/topProduct";
import TopSupplier from "app/pages/report/topSupplier";
import PaymentReportPage from "app/pages/report/paymentReport";
import CreditRecapReportPage from "app/pages/report/creditRecap";
import CreditHistoryPage from "app/pages/report/creditHistory";

const routes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/report/daily-omzet",
    element: <DailyOmzet />,
  },
  {
    path: "/report/top-customer",
    element: <TopCustomer />,
  },
  {
    path: "/report/top-sales",
    element: <TopSales />,
  },
  {
    path: "/report/top-product",
    element: <TopProduct />,
  },
  {
    path: "/report/top-supplier",
    element: <TopSupplier />,
  },
  {
    path: "/report/payment",
    element: <PaymentReportPage />,
  },
  {
    path: "/report/credit-recap",
    element: <CreditRecapReportPage />,
  },
  {
    path: "/report/credit-history",
    element: <CreditHistoryPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/sales_order",
    element: <SalesOrderPage />,
  },
  {
    path: "/sales_order/edit_request",
    element: <SalesOrderEditRequestPage />,
  },
  {
    path: "/sales_order/create",
    element: <CreateSalesOrderPage />,
  },
  {
    path: "/sales_order/update/:id",
    element: <UpdateSalesOrderPage />,
  },
  {
    path: "/sales_order/antrian_produk/:product_code/:color_name/:size_name/:product_id/:color_variant_id/:size_variant_id/:min_total",
    element: <CustomerOrderQueuePage />,
  },
  {
    path: "/sales_order/antrian_produk",
    element: <CustomerOrderList />,
  },
  {
    path: "/sales_order/antrian_produk_grid",
    element: <CustomerOrderListGrid />,
  },
  {
    path: "/sales_order_edit_request",
    element: <SalesOrderEditRequestPage />,
  },
  {
    path: "/sales_order_edit_request/create/:salesOrderId",
    element: <CreateSalesOrderEditRequestPage />,
  },
  {
    path: "/sales_order_edit_request/update/:id/:tabCode",
    element: <UpdateSalesOrderEditRequestPage />,
  },
  {
    path: "/purchase_order",
    element: <PurchaseOrderPage />,
  },
  {
    path: "/purchase_order/create",
    element: <CreatePurchaseOrderPage />,
  },
  {
    path: "/purchase_order/update/:id",
    element: <UpdatePurchaseOrder />,
  },
  {
    path: "/receive_order",
    element: <ReceiveOrderPage />,
  },
  {
    path: "/receive_order/update/:id",
    element: <UpdateReceiveOrder />,
  },
  {
    path: "/packing_order",
    element: <PackingOrderPage />,
  },
  {
    path: "/delivery",
    element: <DeliveryPage />,
  },
  {
    path: "/delivery/order_belum_dibeli",
    element: <OrderBelumDibeliPage />,
  },
  {
    path: "/delivery_order",
    element: <DeliveryOrderPage />,
  },
  {
    path: "/invoice",
    element: <InvoicePage />,
  },
  {
    path: "/invoice/create",
    element: <CreateInvoicePage />,
  },
  {
    path: "/invoice/detail/:id",
    element: <InvoiceDetailPage />,
  },
  {
    path: "/profile",
    element: <UserProfilePage />,
  },
  {
    path: "/color-variant",
    element: <ColorVariant />,
  },
  {
    path: "/size-variant",
    element: <SizeVariantPage />,
  },
  {
    path: "/color-variant",
    element: <ColorVariant />,
  },
  {
    path: "/category",
    element: <CategoryPage />,
  },
  {
    path: "/product",
    element: <ProductPage />,
  },
  {
    path: "/product/detail/:id",
    element: <ProductDetail />,
  },
  {
    path: "/customer/*",
    element: <CustomerPage />,
  },
  {
    path: "/supplier/*",
    element: <SupplierPage />,
  },
];

export default routes;
