import axios from "../axios";
import h from "../helpers";
import { clientIp } from "../api-address";

const payment = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("payments"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("payments/" + id));
    return data;
  },
  store: async (invoice_id, items) => {
    const { data } = await axios.post(h.baseUrl("payments"), {
      invoice_id,
      items,
      ip_address: await clientIp(),
    });
    return data;
  },
  update: async (
    id,
    invoice_id,
    method,
    payment_date,
    payment_time,
    ref_number,
    bank_name,
    account_number,
    total_payment,
    status,
    mode
  ) => {
    const { data } = await axios.put(h.baseUrl("payments/" + id), {
      invoice_id,
      method,
      payment_date,
      payment_time,
      ref_number,
      bank_name,
      account_number,
      total_payment,
      status,
      mode,
      ip_address: await clientIp(),
    });
    return data;
  },
  delete: async (id) => {
    const ip = await clientIp();
    const { data } = await axios.delete(
      h.baseUrl("payments/" + id + `?ip_address=${ip}`)
    );
    return data;
  },
};

export default payment;
