import axios from "../axios";
import h from "../helpers";
import { clientIp } from "../api-address";

const receiveOrder = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("receive-orders"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("receive-orders/" + id));
    return data;
  },
  store: async (items) => {
    const { data } = await axios.post(h.baseUrl("receive-orders"), {
      items,
      ip_address: await clientIp(),
    });
    return data;
  },
  update: async (id, status) => {
    const { data } = await axios.put(h.baseUrl("receive-orders/" + id), {
      status,
      ip_address: await clientIp(),
    });
    return data;
  },
  delete: async (id) => {
    const ip = await clientIp();
    const { data } = await axios.delete(
      h.baseUrl("receive-orders/" + id + `?ip_address=${ip}`)
    );
    return data;
  },
};

export default receiveOrder;
