import { useApplicationModals } from "app/common/hooks";
import { ModalMolecule } from "app/components/molecules/modal";
import api from "app/integration/api";
import { useUpdateForm } from "app/integration/common/hooks";
import { useEffect } from "react";
import * as Yup from "yup";
import AppModals from ".";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "app/components/atoms/button";
import ClientLogo from "img/client-logo-square.JPG";

function CreateDeliveryStickerModal({ onSuccess = () => {} }) {
  const { showStates, closeModal, getPayloads } = useApplicationModals();
  const payloads = getPayloads("deliveryOrder.sticker");
  // console.log(payloads);

  const handleClose = () => closeModal("deliveryOrder.sticker");
  const handleSuccess = () => {
    closeModal("deliveryOrder.sticker");
    onSuccess();
  };

  var isError = false;
  const { openModal, setPayloads } = useApplicationModals();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        code: "",
        status: 3,
        file: null,
        packing_picture: null,
        resi_number: "",
        items: [],
      },
      validationSchema: Yup.object().shape({
        name: Yup.string(),
      }),
      onSubmit: async (values) => {
        try {
          if (values.status == 3) {
            await api.deliveryOrder.update({
              id: values.id,
              status: values.status,
              packing_picture: values.file,
            });
          } else if (values.status == 4) {
            await api.deliveryOrder.update({
              id: values.id,
              status: values.status,
              resi_number: values.resi_number,
            });
          }

          isError = false;
        } catch (error) {
          setPayloads("errorMessage.view", {
            title: "Create Packing Order",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Server Error",
          });
          isError = true;
          openModal("errorMessage.view");
        }
      },
      onSuccess: !isError && handleSuccess,
    });

  const setAddress = (address) => {
    if (address) {
      const {
        city_name,
        desc,
        district_name,
        state_name,
        sub_district_name,
        zipcode,
      } = address;

      return `${desc}, kel: ${sub_district_name}, kec: ${district_name}, ${city_name}, ${state_name}, ${zipcode}`;
    }

    return "";
  };

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      id: payloads?.id,
      code: payloads?.code,
      packing_picture: payloads?.packing_picture,
      resi_number: payloads?.resi_number,
      status: payloads?.status == 2 ? 3 : 4,
      items: payloads?.OrderItems?.map((item) => ({
        product_name: item?.master_product?.name,
        code: item?.master_product?.code,
        size: item?.master_size?.name,
        color: item?.master_color?.name,
        qty: item?.qty,
      })),
    });
  }, [payloads]);

  return (
    <ModalMolecule
      show={showStates["deliveryOrder.sticker"]}
      title="Stiker Delivery"
      onClose={handleClose}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <div
        ref={componentRef}
        className="border w-[378px] h-full print:border-none print:w-full m-auto py-2 px-4 text-sm flex flex-col justify-between"
      >
        <div className="flex justify-between text-xs">
          <span>{payloads?.code}</span>
          <span>{payloads?.SalesOrder?.order_code}</span>
        </div>
        <p className="text-xs font-light">by {payloads?.MUser?.email}</p>
        <div className="flex justify-center">
          <img src={ClientLogo} alt="Logo The Vow" className="h-24 w-64 object-cover" />
        </div>
        <div className="text-base flex flex-col justify-between flex-grow mt-2">
          <div>
            <p>
              Nama: {payloads?.MCustomer?.firstName}{" "}
              {payloads?.MCustomer?.middleName} {payloads?.MCustomer?.lastName}
            </p>
            <p>Alamat: {setAddress(payloads?.SalesOrder?.Address)}</p>
            <p>Telp: {payloads?.MCustomer?.phone}</p>
          </div>
          <p>
            Total Items:{" "}
            {payloads?.OrderItems?.map((orderDetail) => orderDetail.qty).reduce(
              (partialSum, a) => partialSum + a,
              0
            )}{" "}
            pcs
          </p>
        </div>
        <div className="text-sm">
          <div className="my-4 uppercase">
            <div className="flex justify-between items-center">
              <p>!! INFO PENTING !!</p>
            </div>
            <p className="mt-2">
              Segala bentuk komplain harus disertakan video unboxing (tanpa
              terputus) dari awal paket belum dibuka sampai dipastikan pesanan
              sesuai. Jika tidak ada, mohon maaf, komplain tidak dapat dilayani.
            </p>
          </div>
          <p className="uppercase">Thank you for shopping with us!</p>
          <p>From: THE VOW ❤</p>
          <p>Telp: 082169805656</p>
        </div>
      </div>
      <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
        <Button onClick={handlePrint} type="submit">
          Print Stiker
        </Button>
      </div>
    </ModalMolecule>
  );
}

export default CreateDeliveryStickerModal;
