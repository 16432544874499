import axios from "../axios";
import h from "../helpers";
import { clientIp } from "../api-address";

const requestApprove = {
  index: async (params) => {
    const { data } = await axios.get(h.baseUrl("request-approves"), {
      params: { ...params },
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("request-approves/" + id));
    return data;
  },
  store: async (
    id,
    customer_id,
    address_id,
    note_order,
    delivery_fee,
    courier_package,
    items
  ) => {
    const { data } = await axios.post(h.baseUrl("request-approves"), {
      id,
      customer_id,
      address_id,
      note_order,
      delivery_fee,
      courier_package,
      items,
      ip_address: await clientIp(),
    });
    return data;
  },
  update: async (
    id,
    customer_id,
    address_id,
    delivery_fee,
    note_order,
    courier_package,
    items,
    status
  ) => {
    const { data } = await axios.put(h.baseUrl("request-approves/" + id), {
      id,
      customer_id,
      address_id,
      delivery_fee,
      note_order,
      courier_package,
      items,
      status,
      ip_address: await clientIp(),
    });
    return data;
  },
  delete: async (id) => {
    const ip = await clientIp();
    const { data } = await axios.delete(
      h.baseUrl("request-approves/" + id + `?ip_address=${ip}`)
    );
    return data;
  },
};

export default requestApprove;
