import {
  BadgeButton,
  IconButton,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import { SearchInput } from "app/components/atoms/input";
import { FormHeader } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { useNavigate } from "react-router-dom";

function CustomerPageTable({
  customers,
  onDeleteCustomerClick = () => {},
  // query = "",
  onQueryChange = () => {},
  customerPaginationProps,
  params,
  setParams,
}) {
  const navigate = useNavigate();

  return (
    <Table.Container>
      <Table.UtilitiesWrapper>
        <Table.SearchBarWrapper>
          <FormHeader appearance="none">
            <SearchInput
              placeholder="Cari Customer"
              type="text"
              value={params.search}
              onChange={(e) => setParams({ ...params, search: e.target.value })}
            />
          </FormHeader>
        </Table.SearchBarWrapper>
        {/* <Table.UtilitiesButtonWrapper>
          <IconButton icon={PrinterIcon} />
        </Table.UtilitiesButtonWrapper> */}
      </Table.UtilitiesWrapper>
      <Table.Wrapper asIndex={true}>
        <Table.Header>
          <Table.HeaderRow>
            <Table.Heading>Nama Lengkap</Table.Heading>
            <Table.Heading>Kontak</Table.Heading>
            <Table.Heading align="right">Kredit</Table.Heading>
            <Table.Heading></Table.Heading>
          </Table.HeaderRow>
        </Table.Header>
        <Table.Body>
          {customers?.map((customer, key) => (
            <Table.BodyRow key={key}>
              <Table.Cell size="sm">
                <p>
                  {customer?.firstName} {customer?.middleName}{" "}
                  {customer?.lastName}
                </p>
              </Table.Cell>
              <Table.Cell size="sm">
                <div className="block">
                  <p>{customer.phone}</p>
                  <p className="text-xs text-gray-400 font-light">
                    {customer.email}
                  </p>
                </div>
              </Table.Cell>
              <Table.Cell size="sm" align="right">
                <Typography.Currency number={customer.credit} />
              </Table.Cell>
              <Table.Cell size="sm">
                <div className="flex">
                  <BadgeButton
                    href={"/customer/detail/" + customer.id}
                  >
                    View
                  </BadgeButton>
                  {/* <IconButton
                    icon={TrashIcon}
                    onClick={(e) => onDeleteCustomerClick(customer.id)}
                  /> */}
                </div>
              </Table.Cell>
            </Table.BodyRow>
          ))}
        </Table.Body>
      </Table.Wrapper>
      <Table.PaginationWrapper>
        <ServerSidePaginationButtons
          paginationProps={customerPaginationProps}
          params={params}
          setParams={setParams}
        />
      </Table.PaginationWrapper>
    </Table.Container>
  );
}

export default CustomerPageTable;
