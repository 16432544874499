import { useSelector, useDispatch } from "react-redux";
import { toggle } from "app/features/darkMode/darkModeSlice";
import { useLoginForm } from "app/integration/forms";
import { BodyClassNameOverrider } from "app/components/atoms/overrider";
import { SunIcon, MoonIcon } from "@heroicons/react/24/solid";
import { Formik } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { ErrorMessage } from "app/components/atoms/form";
import { Button } from "app/components/atoms/button";

function useController() {
  const darkMode = useSelector((state) => state.darkMode.value);
  const dispatch = useDispatch();

  const onToggleDarkMode = e => dispatch(toggle());
  
  const loginForm = useLoginForm();

  return {
    darkMode,
    onToggleDarkMode,
    loginForm,
  };
}

function LoginPage() {
  const { darkMode, onToggleDarkMode, loginForm } = useController();

  return (
    <BodyClassNameOverrider className={darkMode ? "dark" : ""}>
      <div className="bg-primary-600 dark:bg-gray-800 h-screen flex justify-center items-center">
        <div className="absolute top-5 right-5">
          <button onClick={onToggleDarkMode}>
            {darkMode && <MoonIcon className="h-5 w-5 text-white" />}
            {!darkMode && <SunIcon className="h-5 w-5 text-white" />}
          </button>
        </div>
        <div className="bg-white dark:bg-gray-900 px-10 py-12 space-y-8 rounded-lg shadow w-full md:mx-auto md:max-w-md">
          <h1 className="text-gray-900 dark:text-gray-100 text-3xl font-bold">
            Login to The Vow
          </h1>
          <Formik {...loginForm}>
            {({
              values,
              errors,
              status,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className="space-y-2" onSubmit={handleSubmit}>
                <InputMolecule
                  label="Your Email"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  errorMessage={errors.email && touched.email && errors.email}
                />
                <InputMolecule
                  label="Your Password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  errorMessage={
                    errors.password && touched.password && errors.password
                  }
                />
                {status && <ErrorMessage>{status}</ErrorMessage>}
                {/* <div className="flex justify-end items-center">
                    <a
                      href="#"
                      className="text-sm text-primary-600 ml-auto transition-colors duration-300 ease-in-out hover:underline"
                    >
                      Lost Password?
                    </a>
                  </div> */}
                <div>
                  <div className="mt-8">
                    <Button type="submit" loading={isSubmitting}>
                      Login
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </BodyClassNameOverrider>
  );
}

export default LoginPage;
