import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";

function MainLayoutHeadingButton({ buttons = [] }) {
  return (
    <Fragment>
      {buttons.map((button, key) => (
        <Fragment key={key}>
          {button.type === "button" && (
            <Button onClick={button.onClick}>{button.label}</Button>
          )}
          {button.type === "routerButton" && (
            <RouterButton to={button.to}>{button.label}</RouterButton>
          )}
          {button.type === "menuButton" && (
            <MenuButton
              items={button.items}
              popupLocation="bottom"
              onClick={button.onClick}
              href={button.href}
            >
              {button.label}
            </MenuButton>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
}

function Button({ children, onClick = () => {} }) {
  return (
    <button
      className="border-primary-600 bg-primary-900 text-slate-100 hover:border-primary-400 hover:bg-primary-700 dark:border-primary-300 dark:bg-primary-600 dark:hover:border-primary-400 dark:hover:bg-primary-400 inline-block cursor-pointer rounded-lg text-center text-base font-medium transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 dark:focus:ring-offset-gray-900 px-4 py-2 "
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function RouterButton({ to, children }) {
  return (
    <Link
      className="border-primary-600 bg-primary-900 text-slate-100 hover:border-primary-400 hover:bg-primary-400 dark:border-primary-300 dark:bg-primary-600 dark:hover:border-primary-400 dark:hover:bg-primary-400 inline-block cursor-pointer rounded-lg text-center text-base font-medium transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 dark:focus:ring-offset-gray-900 px-4 py-2"
      to={to}
    >
      {children}
    </Link>
  );
}

function MenuButton({
  children,
  onClick = () => {},
  icon,
  popupLocation,
  items = [],
  href = "#"
}) {
  return (
    <span className="relative z-20 inline-flex w-full">
      <a
        type="button"
        className="relative inline-flex flex-grow items-center justify-center rounded-l-md pl-4 whitespace-nowrap max-w-[150px] truncate bg-primary-900 text-light-100 hover:border-primary-400 hover:bg-primary-700 dark:border-primary-300  dark:bg-primary-500 dark:hover:border-primary-400 dark:hover:bg-primary-400 py-2 font-medium px-2 focus:bg-primary-600 transition-colors duration-500 ease-in-out"
        onClick={onClick}
        href={href}
      >
        {children}
      </a>
      <Menu as="span" className="relative -ml-px block">
        <Menu.Button className="relative inline-flex h-full flex-grow-0 items-center rounded-r-md py-2 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 border-primary-600 bg-primary-900 text-light-100 hover:border-primary-400 hover:bg-primary-700 dark:border-primary-300  dark:bg-primary-500 dark:hover:border-primary-400 dark:hover:bg-primary-400 focus:bg-primary-600 px-2 transition-colors duration-500 ease-in-out">
          <span className="sr-only">Open options</span>
          {icon === "ellipsis" ? (
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          )}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={clsx(
              popupLocation === "bottom" ? "" : " bottom-10",
              "absolute right-0 mt-2 -mr-1 w-40 lg:w-56 z-20 origin-top-right rounded-md bg-white text-left shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none dark:bg-gray-800 dark:ring-gray-700"
            )}
          >
            <div className="py-1">
              {items.map((item, key) => (
                <Menu.Item key={key}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={clsx(
                        active
                          ? "text-gray-900 dark:text-gray-100"
                          : "text-gray-700 dark:text-gray-200",
                        item.disabled
                          ? "pointer-events-none block px-4 py-2 text-sm text-gray-300 hover:bg-gray-100 dark:text-gray-600 dark:hover:bg-gray-500"
                          : "block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-400"
                      )}
                      // target="_blank"
                      onClick={item.onClick}
                    >
                      {item.label}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </span>
  );
}

export default MainLayoutHeadingButton;
