import { useApplicationModals } from "app/common/hooks";
import {
  BadgeButton,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import { Input } from "app/components/atoms/input";
import { FormHeader } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import { usePaginatedCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const breadcrumbItems = [
  { label: "Sales", href: "/sales_order" },
  { label: "Antrian Produk", href: "#" },
];

function CustomerOrderList() {
  const default_limit = process.env.REACT_APP_DEFAULT_LIMIT_QUEUE;
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();
  const typingTimer = useRef(null);
  const doneTypingInterval = 2000;

  const [code, setCode] = useState("");

  const [params, setParams] = useState({
    product_search: "",
    page: 1,
    limit: default_limit,
  });

  const [
    orderItems,
    setOrderItems,
    orderItemsLoader,
    collectOrderItems,
    orderItemPaginationProps,
  ] = usePaginatedCollector({
    modelName: "orderItem",
    methodName: "customerOrderList",
    params: params,
  });

  useEffect(() => {
    collectOrderItems(params);
  }, [params]);

  const handleKeyUp = () => {
    clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(doneTyping, doneTypingInterval);
  };

  const handleKeyDown = () => {
    clearTimeout(typingTimer.current);
  };

  const doneTyping = () => {
    let set_limit = default_limit;
    if (code === "") {
      set_limit = default_limit;
    } else {
      set_limit = 10000;
    }
    setParams({
      ...params,
      product_search: code,
      limit: set_limit,
    });
    collectOrderItems(params);
  };

  const getTotalPembelian = (item) => {
    const buying_list = item?.buying_list;
    if (!buying_list) {
      return { total: item?.total_qty, color: "green", min: 0 };
    } else {
      let total = item?.total_qty;
      let product_code = item?.product_code;
      let size_name = item?.size_name;
      let color_name = item?.color_name;

      let full_product_name = `${product_code} ${color_name} ${size_name}`;

      let buying_list_array = buying_list.split(",");
      buying_list_array.forEach((buying_item) => {
        let buying_item_array = buying_item.split(":");
        let buying_product_name = buying_item_array[0].trim();
        let buying_qty = buying_item_array[1].trim().replace("pcs", "");
        if (buying_product_name === full_product_name) {
          total = Number(total) + Number(buying_qty);
        }
      });

      return {
        total,
        color: Number(total) >= Number(item?.total_qty) ? "green" : "red",
        min: Number(item?.total_qty) - Number(total),
      };
    }
  };

  return (
    <MainLayout
      activeSidebarNavigation="sales"
      activeSidebarChild="product_queue"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Daftar Antrian Produk"
      headingButtons={[]}
    >
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <FormHeader appearance="none">
              <Input
                label="Nama Product"
                placeholder="Cari produk..."
                type="text"
                value={code}
                onChange={async (e) => setCode(e.target.value)}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
              />
            </FormHeader>
          </Table.SearchBarWrapper>
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={true}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>No.</Table.Heading>
              <Table.Heading>Supplier</Table.Heading>
              <Table.Heading>Product</Table.Heading>
              <Table.Heading>Color</Table.Heading>
              <Table.Heading>Size</Table.Heading>
              <Table.Heading align="right">Orderan</Table.Heading>
              <Table.Heading align="right">Sudah Dibeli</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {orderItems?.map((item, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>
                  <p>{key + 1}</p>
                </Table.Cell>
                <Table.Cell>
                  <p>{item?.supplier_name}</p>
                </Table.Cell>

                <Table.Cell className="lg:table-cell">
                  <div className="flex items-center gap-2">
                    {item?.image_url && (
                      <ImageWithPreview
                        size="base"
                        src={`${process.env.REACT_APP_AWS_LINK_URL}${item?.image_url}`}
                      ></ImageWithPreview>
                    )}
                    <p>{item?.product_code}</p>
                  </div>
                </Table.Cell>
                <Table.Cell className="lg:table-cell">
                  <div className="text-left">
                    <p>{item?.color_name}</p>
                  </div>
                </Table.Cell>
                <Table.Cell className="lg:table-cell">
                  <div className="text-left">
                    <p>{item?.size_name}</p>
                  </div>
                </Table.Cell>

                <Table.Cell>
                  <p className="text-right">
                    <Typography.Currency number={Number(item?.total_qty)} />
                  </p>
                </Table.Cell>

                <Table.Cell>
                  <p
                    className="text-right"
                    style={{ color: getTotalPembelian(item)?.color }}
                  >
                    <Typography.Currency
                      number={Number(getTotalPembelian(item)?.total)}
                    />
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <div className="flex space-x-2">
                    <div className="flex">
                      {/* <IconButton
                        icon={PencilSquareIcon}
                        href={
                          "/sales_order/customer_order_queue/" +
                          item.product_code +
                          "/" +
                          item.color_name +
                          "/" +
                          item.size_name +
                          "/" +
                          item.product_id +
                          "/" +
                          item.color_variant_id +
                          "/" +
                          item.size_variant_id +
                          "/" +
                          Number(getTotalPembelian(item)?.min)
                        }
                      /> */}
                      <BadgeButton
                        color="green"
                        onClick={(e) => {
                          navigate(
                            "/sales_order/antrian_produk/" +
                              item.product_code +
                              "/" +
                              item.color_name +
                              "/" +
                              item.size_name +
                              "/" +
                              item.product_id +
                              "/" +
                              item.color_variant_id +
                              "/" +
                              item.size_variant_id +
                              "/" +
                              Number(getTotalPembelian(item)?.min)
                          );
                        }}
                      >
                        Lihat Antrian
                      </BadgeButton>
                    </div>
                  </div>
                </Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
        <Table.PaginationWrapper>
          <ServerSidePaginationButtons
            paginationProps={orderItemPaginationProps}
            params={params}
            setParams={setParams}
          />
        </Table.PaginationWrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default CustomerOrderList;
