import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import { Button } from "app/components/atoms/button";
import Table from "app/components/atoms/table";
import { InputMolecule } from "app/components/molecules/form";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import { ModalMolecule } from "app/components/molecules/modal";
import api from "app/integration/api";
import { useUpdateForm } from "app/integration/common/hooks";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import AppModals from ".";
import Resizer from "react-image-file-resizer";

function CreateDeliveryPackingModal({ onSuccess = () => {} }) {
  const [isLoading, setIsLoading] = useState(false);

  const { showStates, closeModal, getPayloads } = useApplicationModals();
  const payloads = getPayloads("deliveryOrder.packing");

  const handleClose = () => {
    closeModal("deliveryOrder.packing");
    onSuccess();
  };
  const handleSuccess = () => {
    closeModal("deliveryOrder.packing");
    onSuccess();
  };

  const now = DateTime.now();
  const dt = now.toISODate();
  const time = now.toLocaleString(DateTime.TIME_24_SIMPLE);

  var isError = false;
  const { openModal, setPayloads } = useApplicationModals();

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        code: "",
        status: 3,
        file: null,
        image_date: "",
        packing_picture: null,
        resi_number: "",
        items: [],
      },
      validationSchema: Yup.object().shape({
        name: Yup.string(),
        // file: Yup.mixed().required("Harap upload foto hasil packing terlebih dahulu."),
        status: Yup.number(),
        file: Yup.mixed().when("status", (status, schema) => {
          if (status == 3) {
            return schema.required(
              "Harap upload foto hasil packing terlebih dahulu."
            );
          }
          return schema.notRequired();
        }),
        resi_number: Yup.string().when("status", (status, schema) => {
          if (status == 4) {
            return schema.required("Nomor Resi harus diisi.");
          }
          return schema;
        }),
      }),
      onSubmit: async (values) => {
        try {
          if (values.status == 3) {
            await api.deliveryOrder.update({
              id: values.id,
              status: values.status,
              packing_picture: values.file,
              image_date: values.image_date,
            });
          } else if (values.status == 4) {
            await api.deliveryOrder.update({
              id: values.id,
              status: values.status,
              resi_number: values.resi_number,
            });
          }

          // Reset file ketika sukses
          values.file = null;

          isError = false;
        } catch (error) {
          setPayloads("errorMessage.view", {
            title: "Create Packing Order",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Server Error",
          });
          isError = true;
          openModal("errorMessage.view");
        }
      },
      onSuccess: () => {
        // Reset initial values: file
        setInitialValues({
          ...initialValues,
          file: null,
        });

        if (!isError) {
          handleSuccess();
        }
      },
    });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const fileChangeHandler = async (e, index) => {
    let beforeResize = e.target.files[0];
    console.log("before resize file: ", beforeResize);
    let imageResize = await resizeFile(e.target.files[0]);

    console.log("after resize: ", imageResize);

    let fileType = imageResize.type;
    let fileName = imageResize.name;

    if (
      fileType.search(/jpeg|jpg|png/gi) >= 0 ||
      fileName.search(/.rar/gi) >= 0
    ) {
      console.log(
        "file ini di perbolehkan untuk di upload",
        fileType.search(/jpeg|jpg|pdf|xls|zip|rar/gi),
        fileName.search(/.rar/gi)
      );
      // const uploaded = await api.uploadImage.product({ image: imageResize });

      setInitialValues({
        ...initialValues,
        file: imageResize,
        image_date: beforeResize.lastModified,
      });
    } else {
      console.log(
        "file tidak dapat di upload.",
        fileType.search(/jpeg|jpg|pdf|xls|zip|rar/gi),
        fileName.search(/.rar/gi)
      );
      alert(`Jenis File ${fileType} tidak dapat di upload`);
    }
  };

  const updateStatusPacking = async (e, id) => {
    try {
      setIsLoading(true);
      const { message, status, data } = await api.orderItem.orderPackingUpdate(
        id,
        e.target.checked ? true : false
      );

      if (message == "Data update success" && status == "success") {
        setIsLoading(false);
        return true;
      } else {
        throw new Error("Gagal update status packing");
      }
    } catch (error) {
      setIsLoading(false);
      alert("Gagal update status packing");
      return false;
    }
  };
  // const fileChangeHandler = (e, index) => {
  //   let fileUpload = Array.from(e.target.files);
  //   let fileType = fileUpload[0].type;
  //   let fileName = fileUpload[0].name;

  //   console.log(
  //     "🚀 ~ file: multiple_upload.vue ~ line 71 ~ fileChangeHandler ~ fileType",
  //     fileUpload
  //   );

  //   fileUpload["file"] = fileUpload;
  //   fileUpload["fileName"] = fileUpload[0].name;

  //   if (
  //     fileType.search(/jpeg|jpg|png/gi) >= 0 ||
  //     fileName.search(/.rar/gi) >= 0
  //   ) {
  //     console.log(
  //       "file ini diperbolehkan untuk di upload",
  //       fileType.search(/jpeg|jpg|pdf|xls|zip|rar/gi),
  //       fileName.search(/.rar/gi)
  //     );
  //     setInitialValues({
  //       ...initialValues,
  //       file: fileUpload[0],
  //     });
  //   } else {
  //     console.log(
  //       "file tidak dapat di upload.",
  //       fileType.search(/jpeg|jpg|pdf|xls|zip|rar/gi),
  //       fileName.search(/.rar/gi)
  //     );
  //     alert(`Jenis File ${fileType} tidak dapat di upload`);
  //   }
  // };

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      id: payloads.id,
      code: payloads.code,
      packing_picture: payloads?.packing_picture,
      image_date: payloads?.image_date,
      resi_number: payloads?.resi_number,
      status: payloads.status == 2 ? 3 : 4,
      items: payloads?.OrderItems?.map((item) => ({
        id: item?.id,
        product_name: item?.master_product?.name,
        imageUrl: item?.master_product?.imageUrl,
        code: item?.master_product?.code,
        size: item?.master_size?.name,
        color: item?.master_color?.name,
        qty: item?.qty,
        status_packing: item?.status_packing,
      })),
    });
  }, [payloads]);

  return (
    <ModalMolecule
      show={showStates["deliveryOrder.packing"]}
      title="Packing Pengiriman"
      onClose={handleClose}
    >
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-200 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-md">
            <p>Loading...</p>
          </div>
        </div>
      )}
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>{values.code}</div>
            {/* <div>{values?.SalesOrder?.order_code}</div> */}
            <Table.Container>
              <Table.Wrapper asIndex={false}>
                <Table.Header>
                  <Table.HeaderRow>
                    <Table.Heading>Produk</Table.Heading>
                    <Table.Heading>Varian</Table.Heading>
                    <Table.Heading align="right">Qty</Table.Heading>
                    <Table.Heading align="right">Status</Table.Heading>
                    <Table.Heading align="right"></Table.Heading>
                  </Table.HeaderRow>
                </Table.Header>
                <Table.Body>
                  {values?.items
                    ?.sort((a, b) => a.id - b.id)
                    .map((item, index) => (
                    <Table.BodyRow key={index}>
                      <Table.Cell>
                        <div className="flex gap-2 items-center">
                          {item?.imageUrl && item?.imageUrl != "" ? (
                            <ImageWithPreview
                              size="lg"
                              src={`${process.env.REACT_APP_AWS_LINK_URL}${item?.imageUrl}`}
                            ></ImageWithPreview>
                          ) : null}
                          <div>
                            {item?.product_name} {item?.id}
                            <div>{item?.code}</div>
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div className="flex flex-wrap gap-2 items-center">
                          <Badge color="gray">{item?.size}</Badge>
                          <Badge color="gray">{item?.color}</Badge>
                        </div>
                      </Table.Cell>
                      <Table.Cell align="right">{item?.qty}</Table.Cell>
                      <Table.Cell align="right">
                        <Badge color={item?.status_packing ? "green" : "gray"}>
                          {item?.status_packing
                            ? "Sudah Packing"
                            : "Belum Packing"}
                        </Badge>
                      </Table.Cell>
                      <Table.Cell align="right">
                        <input
                          type="checkbox"
                          checked={item?.status_packing ? true : false}
                          onChange={async (e) => {
                            const status = updateStatusPacking(e, item?.id);

                            if (status) {
                              values.items[index].status_packing = e.target
                                .checked
                                ? true
                                : false;
                              setInitialValues({
                                ...initialValues,
                                items: values.items,
                              });
                            }
                          }}
                        />
                      </Table.Cell>
                    </Table.BodyRow>
                  ))}
                </Table.Body>
              </Table.Wrapper>
            </Table.Container>
            {payloads.status == 2 && (
              <>
                <div>Upload foto hasil packing di sini:</div>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => fileChangeHandler(e)}
                />
                <p className="text-danger-600">
                  {errors.file && touched.file && errors.file}
                </p>
              </>
            )}
            {payloads.status == 3 && (
              <>
                <div className="bg-dark-100 p-4 rounded-md border border-dark-200">
                  <InputMolecule
                    label="Nomor Resi Pengiriman"
                    type="text"
                    name="resi_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.resi_number}
                    errorMessage={
                      errors.resi_number &&
                      touched.resi_number &&
                      errors.resi_number
                    }
                    placeholder="contoh: 11LP1680679892004"
                  />
                </div>
                <p>
                  Tanggal Foto:{" "}
                  <span className="text-danger-600">
                    {new Date(
                      payloads && Number(payloads.image_date)
                    ).toLocaleString()}
                  </span>
                </p>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}file/${payloads?.packing_picture}`}
                />
              </>
            )}

            {payloads.status == 4 && (
              <div>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}file/${payloads?.packing_picture}`}
                />

                <p>No. Resi: {values.resi_number}</p>
              </div>
            )}
            <div></div>
            <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
              <Button type="submit">
                {payloads.status == 3
                  ? "Konfirmasi Pengiriman"
                  : payloads.status == 4
                  ? "Selesai"
                  : "Konfirmasi Packing"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </ModalMolecule>
  );
}

export default CreateDeliveryPackingModal;
