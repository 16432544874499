import { FolderIcon } from "@heroicons/react/24/solid";
import { useApplicationModals } from "app/common/hooks";
import {
  BadgeButton,
  Button,
  IconButton,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import Table from "app/components/atoms/table";
import { InputMolecule } from "app/components/molecules/form";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import api from "app/integration/api";
import { useUpdateForm, useCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik, getIn } from "formik";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "style/height.module.css";
import * as Yup from "yup";
import { Input } from "app/components/atoms/input";
import { FormHeader } from "app/components/atoms/layout";
import { Badge } from "app/components/atoms/badge";
import { SelectSearch } from "app/components/atoms/select";

const breadcrumbItems = [
  { label: "Gudang", href: "/receive_order" },
  { label: "Penerimaan", href: "/receive_order" },
  { label: "Terima Barang", href: "#" },
];

function UpdateReceiveOrder() {
  let { id } = useParams();
  const navigate = useNavigate();
  let isError = false;

  const handleSuccess = (res) => {
    console.log(res);
    navigate("/receive_order");
  };

  const [isLoading, setIsloading] = useState(false);
  const [receive, setReceive] = useState([]);
  const [defaultStatus, setDefaultStatus] = useState(1);
  const [isCreateNew, setCreateNew] = useState(false);
  const typingTimer = useRef(null);
  const doneTypingInterval = 2000;
  const [paginationProps, setPaginationProps] = useState([]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // console.log(today);

  const [params, setParams] = useState({
    search: "",
    createdAt: null,
    supplier_id: null,
    status: null,
    is_valid_order: null,
    page: 1,
    limit: 50,
  });

  const [suppliers, setMSuppliers, suppliersLoader, collectMSuppliers] =
    useCollector({ modelName: "supplier" });

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        items: [],
      },
      validationSchema: Yup.object().shape({
        items: Yup.array()
          .of(
            Yup.object().shape({
              qty: Yup.number()
                .required("Qty harus diisi.")
                .min(0, "Qty diterima minimal 0."),
            })
          )
          .min(1, "Minimal harus ada 1 Item yang dibeli."),
      }),
      onSubmit: async (values) => {
        try {
          setIsloading(true);
          let response = await api.receiveOrder.update(
            values.id,
            values.status
          );

          if (response && response.status == "success") {
            isError = false;
            setIsloading(false);
          }
          return response;
        } catch (error) {
          setPayloads("errorMessage.view", {
            title: "Update Peneriamaan Barang",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Server Error",
          });
          isError = true;
          openModal("errorMessage.view");
          setIsloading(false);
        }
      },
      onSuccess: () => !isError && handleSuccess(),
    });

  const { openModal, setPayloads } = useApplicationModals();
  const [currentErrors, setCurrentErrors] = useState([]);

  function runValidations(values) {
    validationSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
        setCurrentErrors([]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.name); // ValidationError
        console.log(err.errors);
        setCurrentErrors(err.errors);

        setPayloads("errorMessage.view", {
          title: "Tambah Order Error",
          data: err.errors,
        });
        openModal("errorMessage.view");
      });
  }

  const getReceive = async () => {
    try {
      console.log("id: ", id);
      const response = await api.receiveOrderItem.index(params);

      if (response && response.status == "success") {
        setInitialValues({
          items: [],
        });
        setReceive(response.data);
        setPaginationProps(response.pagination);
      }
    } catch (err) {
      setReceive([]);
      console.log("error: ", err);
    }
  };

  const [receiveOrderLoadingState, setReceiveOrderLoadingState] = useState([]);

  const updateOrderItem = async (
    item_id,
    qty,
    status,
    receiveAllItem = false
  ) => {
    let newReceiveOrderLoadingState = [...receiveOrderLoadingState];
    newReceiveOrderLoadingState.push(item_id);
    await setReceiveOrderLoadingState(newReceiveOrderLoadingState);
    try {
      const response = await api.receiveOrderItem.update(
        item_id,
        qty,
        status,
        receiveAllItem
      );
      newReceiveOrderLoadingState = [...receiveOrderLoadingState];
      newReceiveOrderLoadingState.splice(
        newReceiveOrderLoadingState.indexOf(item_id),
        1
      );
      await setReceiveOrderLoadingState(newReceiveOrderLoadingState);

      if (response && response.status == "success") {
        console.log("update qty berhasil");
        await getReceive();
      }
    } catch (err) {
      console.log("error: ", err);
    }
  };

  const handleUpdate = (item_id, qty, old_qty) => {
    if (qty < old_qty) {
      setPayloads("receiveOrderItem.update", { id: item_id, qty });
      openModal("receiveOrderItem.update");
    } else {
      updateOrderItem(item_id, qty, false, true);
    }
  };

  const handleKeyUp = () => {
    clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(doneTyping, doneTypingInterval);
  };

  const handleKeyDown = () => {
    clearTimeout(typingTimer.current);
  };

  const doneTyping = () => {
    getReceive();
  };

  useEffect(() => {
    getReceive();
  }, [params]);

  useEffect(() => {
    console.log("receive: ", receive);
    setDefaultStatus(receive.status);
    setInitialValues({
      items: receive?.map((item) => ({
        id: item.id,
        imageUrl: item?.master_product?.imageUrl,
        product_name: item?.master_product?.name,
        product_code: item?.master_product?.code,
        color: item?.master_color?.name,
        size: item?.master_size?.name,
        supplier_id: item.supplier_id,
        product_id: item.product_id,
        size_variant_id: item.size_variant_id,
        color_variant_id: item.color_variant_id,
        status: item.status,
        qty: 0,
        order_qty: item.qty,
        old_qty: item.qty,
        receive_code: item?.ReceiveOrder?.receive_code,
        supplier_firstName: item?.ReceiveOrder?.MSupplier?.firstName,
        supplier_lastName: item?.ReceiveOrder?.MSupplier?.lastName,
        supplier_middleName: item?.ReceiveOrder?.MSupplier?.middleName,
        supplier_nickName: item?.ReceiveOrder?.MSupplier?.nickName,
      })),
    });
    console.log("initial: ", initialValues);
  }, [receive]);

  return (
    <MainLayout
      activeSidebarNavigation="warehouse"
      activeSidebarChild="receive"
      breadcrumbItems={breadcrumbItems}
      pageTitle={`Terima Barang`}
      headingButtons={[]}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <AppModals
        items={["receiveOrderItem.update"]}
        onSuccess={{
          "receiveOrderItem.update": async (status, id, qty) => {
            console.log("status update: ", status, id, qty);
            await updateOrderItem(id, qty, status);
          },
        }}
      />
      <FormHeader appearance="none">
        <SelectSearch
          label="Supplier"
          placeholder="Filter Supplier"
          options={suppliers
            ?.sort(function (a, b) {
              if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase())
                return -1;
              if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase())
                return 1;
              return 0;
            })
            .map((supplier) => ({
              value: supplier.id,
              label:
                supplier.firstName +
                " " +
                supplier.middleName +
                " " +
                supplier.lastName +
                (supplier.nickName ? " (" + supplier.nickName + ")" : ""),
            }))}
          value={params.supplier_id}
          onChange={async (e) => {
            setParams({ ...params, supplier_id: e.target.value });
          }}
        />
        <Input
          label="Nama Product"
          placeholder="Search..."
          type="text"
          value={params.search}
          onChange={async (e) =>
            setParams({ ...params, search: e.target.value })
          }
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
        />
      </FormHeader>
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx("px-2 overflow-y-auto")}
            onSubmit={handleSubmit}
          >
            <div className="overflow-visible">
              <div className="w-full">
                <FieldArray name="items">
                  {(arrayHelpers) => (
                    <Table.Wrapper asIndex={true}>
                      <Table.Header>
                        <Table.HeaderRow>
                          <Table.Heading>Code</Table.Heading>
                          <Table.Heading>Supplier</Table.Heading>
                          <Table.Heading>Nama</Table.Heading>
                          <Table.Heading>Kode</Table.Heading>
                          <Table.Heading>Warna</Table.Heading>
                          <Table.Heading>Size</Table.Heading>
                          <Table.Heading>Dipesan</Table.Heading>
                          <Table.Heading>Qty Diterima</Table.Heading>
                          <Table.Heading>Status</Table.Heading>
                          <Table.Heading></Table.Heading>
                        </Table.HeaderRow>
                      </Table.Header>
                      <Table.Body>
                        {values?.items?.length > 0 &&
                          values.items.map((item, key) => (
                            <Table.BodyRow key={key}>
                              <Table.Cell size="xs">
                                <p>{item.receive_code}</p>
                              </Table.Cell>
                              <Table.Cell size="xs">
                                <p>
                                  {item.supplier_firstName}{" "}
                                  {item.supplier_lastName}
                                </p>
                              </Table.Cell>
                              <Table.Cell size="xs">
                                <div className="flex items-center gap-2">
                                  {item?.imageUrl && (
                                    <ImageWithPreview
                                      src={`${process.env.REACT_APP_AWS_LINK_URL}${item?.imageUrl}`}
                                    ></ImageWithPreview>
                                  )}
                                  <p>{item.product_name}</p>
                                </div>
                              </Table.Cell>
                              <Table.Cell size="xs">
                                <p>{item.product_code}</p>
                              </Table.Cell>

                              <Table.Cell size="xs">
                                <p>{item.color}</p>
                              </Table.Cell>
                              <Table.Cell size="xs">{item.size}</Table.Cell>
                              <Table.Cell size="xs" align="right">
                                <p className="text-center">
                                  {item.status == 1 ? item.order_qty : ""}
                                </p>
                              </Table.Cell>
                              <Table.Cell size="xs">
                                <div className="w-20 text-center">
                                  {item.status == 1 ? (
                                    <InputMolecule
                                      hideLabel={true}
                                      type="number"
                                      name={`items[${key}].qty`}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      value={0}
                                      errorMessage={
                                        getIn(errors, `items[${key}].qty`) &&
                                        getIn(touched, `items[${key}].qty`) &&
                                        getIn(errors, `items[${key}].qty`)
                                      }
                                      align="right"
                                      size="xs"
                                    />
                                  ) : (
                                    item.order_qty
                                  )}
                                </div>
                              </Table.Cell>
                              <Table.Cell size="xs">
                                <Badge
                                  color={item.status == 1 ? "yellow" : "green"}
                                >
                                  {item.status == 1 ? "Tunggu" : "Diterima"}
                                </Badge>
                              </Table.Cell>
                              <Table.Cell size="xs">
                                {item.status == 1 && (
                                  <BadgeButton
                                    color="green"
                                    onClick={(e) => {
                                      const confirmed = window.confirm(
                                        "Apakah Anda yakin menerima barang ini? " +
                                          item.product_name +
                                          " " +
                                          item.size +
                                          " " +
                                          item.color +
                                          " sebanyak " +
                                          item.qty +
                                          " pcs"
                                      );
                                      if (confirmed) {
                                        handleUpdate(
                                          item.id,
                                          item.qty,
                                          item.old_qty
                                        );
                                      }
                                    }}
                                    useLoadingState={true}
                                    loading={receiveOrderLoadingState?.includes(
                                      item.id
                                    )}
                                  >
                                    Terima
                                  </BadgeButton>
                                )}
                              </Table.Cell>
                            </Table.BodyRow>
                          ))}
                      </Table.Body>
                    </Table.Wrapper>
                  )}
                </FieldArray>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Table.PaginationWrapper>
        <ServerSidePaginationButtons
          paginationProps={paginationProps}
          params={params}
          setParams={setParams}
        />
      </Table.PaginationWrapper>
    </MainLayout>
  );
}

export default UpdateReceiveOrder;
