import clsx from 'clsx';

function iconButtonSize(size) {
  return {
    xs: 'h-5 w-5',
    sm: 'h-7 w-7',
    md: 'h-9 w-9',
    lg: 'h-11 w-11',
  }[size];
}
function iconButtonColor(color) {
  return {
    gray: 'text-gray-400 hover:text-gray-600 focus:ring-gray-300 dark:text-gray-500 dark:hover:text-gray-300 dark:focus:ring-gray-500',
    primary: 'text-primary-400 hover:text-primary-600 focus:ring-primary-300 dark:text-primary-500 dark:hover:text-primary-300 dark:focus:ring-primary-500',
    secondary: 'text-secondary-400 hover:text-secondary-600 focus:ring-secondary-300 dark:text-secondary-500 dark:hover:text-secondary-300 dark:focus:ring-secondary-500',
    accent: 'text-accent-400 hover:text-accent-600 focus:ring-accent-300 dark:text-accent-500 dark:hover:text-accent-300 dark:focus:ring-accent-500',
    green: 'text-green-600 hover:text-green-500 focus:ring-green-300 dark:text-green-500 dark:hover:text-green-300 dark:focus:ring-green-500',
    red: 'text-red-400 hover:text-red-500 focus:ring-red-300 dark:text-red-500 dark:hover:text-red-300 dark:focus:ring-red-500',
  }[color];
}

function IconButton({ icon, size = 'sm', onClick = () => {}, color = 'gray', href="#" }) {
  const Icon = icon;

  return (
    <a
      href={href}
      type="button"
      className={clsx(
        "items-center rounded-lg px-1 py-1 text-sm transition-colors duration-300 ease-in-out",
        iconButtonColor(color),
        iconButtonSize(size)
      )}
      onClick={onClick}
    >
      <Icon />
    </a>
  );
}

export default IconButton;
