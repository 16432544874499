import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import {
  BadgeButton,
  IconButton,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import { Input } from "app/components/atoms/input";
import { FormHeader } from "app/components/atoms/layout";
import { SelectSearch } from "app/components/atoms/select";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import {
  useCollector,
  usePaginatedCollector,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const breadcrumbItems = [
  { label: "Sales", href: "#" },
  { label: "Order", href: "#" },
];

function getTotal(orderItems) {
  return orderItems
    ?.map((orderItem) => {
      return (
        orderItem.qty * +orderItem.unit_price +
        orderItem?.variants
          ?.map((item) => +item.price)
          .reduce((partialSum, a) => partialSum + a, 0)
      );
    })
    .reduce((partialSum, a) => partialSum + a, 0);
}

function getMenuQty(orderItems, menu) {
  var qty = 0;
  orderItems?.map((orderItem) => {
    if (orderItem?.Product?.ProductCategory?.name === menu) {
      qty += orderItem.qty;
    }
  });
  return qty;
}

function SalesOrderPage() {
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();

  const [params, setParams] = useState({
    search: "",
    createdAt: null,
    customer_id: null,
    status: null,
    is_valid_order: null,
    page: 1,
    limit: 50,
  });

  const [
    salesOrders,
    setSalesOrders,
    salesOrdersLoader,
    collectSalesOrders,
    salesOrderPaginationProps,
  ] = usePaginatedCollector({ modelName: "salesOrder", params: params });

  const [customerParams, setCustomerParams] = useState({
    search: "",
    page: 1,
    limit: Number(process.env.REACT_APP_CUSTOMER_LIMIT),
  });

  const [customers, setMCustomers, customersLoader, collectMCustomers] =
    useCollector({ modelName: "customer", params: customerParams });

  useEffect(() => {
    collectMCustomers(customerParams);
  }, [customerParams]);

  return (
    <MainLayout
      activeSidebarNavigation="sales"
      activeSidebarChild="order"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Daftar Order"
      headingButtons={[
        {
          label: "Tambah Order",
          type: "routerButton",
          to: "/sales_order/create",
        },
      ]}
    >
      <AppModals
        items={["salesOrder.delete"]}
        onSuccess={{
          "salesOrder.delete": () => collectSalesOrders(),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <FormHeader appearance="none">
              <SelectSearch
                label="Customer"
                placeholder="Filter Customer"
                options={customers
                  ?.sort(function (a, b) {
                    if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase())
                      return -1;
                    if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase())
                      return 1;
                    return 0;
                  })
                  .map((customer) => ({
                    value: customer.id,
                    label:
                      customer.firstName +
                      " " +
                      customer.middleName +
                      " " +
                      customer.lastName +
                      (customer.nickName ? " (" + customer.nickName + ")" : "") +
                      (customer.phone ? " - " + customer.phone + "" : ""),
                  }))}
                value={params.customer_id}
                onChange={(e) =>
                  setParams({ ...params, customer_id: e.target.value })
                }
                onSearch={(text) => {
                  setCustomerParams({ ...customerParams, search: text });
                  if (text == "") {
                    setParams({ ...params, customer_id: null });
                  }
                }}
                apiSearch={true}
              />
              <Input
                label="Tanggal Kirim"
                placeholder="Tanggal Kirim"
                type="date"
                value={params.createdAt}
                onChange={(e) =>
                  setParams({ ...params, createdAt: e.target.value })
                }
              />
            </FormHeader>
          </Table.SearchBarWrapper>
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={true}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>No.</Table.Heading>
              <Table.Heading>Customer</Table.Heading>
              <Table.Heading align="right">Total</Table.Heading>
              <Table.Heading className="hidden md:table-cell">
                Sales
              </Table.Heading>
              <Table.Heading>Status</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {salesOrders?.map((salesOrder, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>
                  <p>{salesOrder.order_code}</p>
                  <Typography.Date
                    format="dd MMM yyyy"
                    size="xs"
                    date={salesOrder.createdAt}
                  />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    {salesOrder.MCustomer?.firstName}{" "}
                    {salesOrder.MCustomer?.middleName}{" "}
                    {salesOrder.MCustomer?.lastName}
                    {salesOrder.delivery_to !== salesOrder.customer_id &&
                      salesOrder.MCustomer?.nickName !== null &&
                      salesOrder.MCustomer?.nickName !== "" &&
                      " (" + salesOrder.MCustomer?.nickName + ")"}
                  </p>
                  <p className="text-gray-500 font-light text-sm">
                    {salesOrder.MCustomer?.phone !== null &&
                      salesOrder.MCustomer?.phone !== "" &&
                      salesOrder.MCustomer?.phone}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p className="text-right">
                    <Typography.Currency
                      number={
                        Number(salesOrder.total_order) +
                        Number(salesOrder.delivery_fee)
                      }
                    />
                  </p>
                </Table.Cell>
                <Table.Cell className="hidden lg:table-cell">
                  <p className="text-left">{salesOrder?.sales_info?.email}</p>
                  {/* <p className="text-center text-danger-500 text-xs font-extralight">
                    (hardcoded)
                  </p> */}
                </Table.Cell>

                <Table.Cell>
                  {salesOrder.invoice_id != null ? (
                    <BadgeButton
                      color="green"
                      href={"/invoice/detail/" + salesOrder?.invoice_id}
                    >
                      Invoice
                    </BadgeButton>
                  ) : (
                    <Badge
                      color={salesOrder.invoice_id != null ? "green" : "yellow"}
                    >
                      {salesOrder.invoice_id != null ? "Invoice" : "Quotation"}
                    </Badge>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex space-x-2">
                    <div className="flex">
                      <IconButton
                        icon={PencilSquareIcon}
                        href={"/sales_order/update/" + salesOrder.id}
                      />
                      <IconButton
                        icon={TrashIcon}
                        onClick={(e) => {
                          setPayloads("salesOrder.delete", {
                            id: salesOrder.id,
                          });
                          openModal("salesOrder.delete");
                        }}
                      />
                    </div>
                  </div>
                </Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
        <Table.PaginationWrapper>
          <ServerSidePaginationButtons
            paginationProps={salesOrderPaginationProps}
            params={params}
            setParams={setParams}
          />
        </Table.PaginationWrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default SalesOrderPage;
