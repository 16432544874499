import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import {
  BadgeButton,
  IconButton,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import { Input } from "app/components/atoms/input";
import { FormHeader } from "app/components/atoms/layout";
import { SelectSearch } from "app/components/atoms/select";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import {
  useCollector,
  usePaginatedCollector,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const breadcrumbItems = [
  { label: "Sales", href: "#" },
  { label: "Request Edit SO", href: "#" },
];

function getTotal(orderItems) {
  return orderItems
    ?.map((orderItem) => {
      return (
        orderItem.qty * +orderItem.unit_price +
        orderItem?.variants
          ?.map((item) => +item.price)
          .reduce((partialSum, a) => partialSum + a, 0)
      );
    })
    .reduce((partialSum, a) => partialSum + a, 0);
}

function getMenuQty(orderItems, menu) {
  var qty = 0;
  orderItems?.map((orderItem) => {
    if (orderItem?.Product?.ProductCategory?.name === menu) {
      qty += orderItem.qty;
    }
  });
  return qty;
}

function SalesOrderEditRequestPage() {
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();

  const [params, setParams] = useState({
    search: "",
    createdAt: null,
    customer_id: null,
    status: null,
    is_valid_order: null,
    page: 1,
    limit: 50,
  });

  const [
    salesOrderEditRequests,
    setSalesOrderEditRequests,
    salesOrderEditRequestsLoader,
    collectSalesOrderEditRequests,
    salesOrderEditRequestPaginationProps,
  ] = usePaginatedCollector({ modelName: "salesOrderEditRequest", params: params });

  const [customerParams, setCustomerParams] = useState({
    search: "",
    page: 1,
    limit: Number(process.env.REACT_APP_CUSTOMER_LIMIT),
  });

  const [customers, setMCustomers, customersLoader, collectMCustomers] =
    useCollector({ modelName: "customer", params: customerParams });

  useEffect(() => {
    collectMCustomers(customerParams);
  }, [customerParams]);

  return (
    <MainLayout
      activeSidebarNavigation="sales"
      activeSidebarChild="edit-request"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Daftar Perubahan Order"
    >
      <AppModals
        items={["salesOrderEditRequest.delete"]}
        onSuccess={{
          "salesOrderEditRequest.delete": () => collectSalesOrderEditRequests(),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <FormHeader appearance="none">
              <SelectSearch
                label="Customer"
                placeholder="Filter Customer"
                options={customers
                  ?.sort(function (a, b) {
                    if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase())
                      return -1;
                    if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase())
                      return 1;
                    return 0;
                  })
                  .map((customer) => ({
                    value: customer.id,
                    label:
                      customer.firstName +
                      " " +
                      customer.middleName +
                      " " +
                      customer.lastName +
                      (customer.nickName ? " (" + customer.nickName + ")" : "") +
                      (customer.phone ? " - " + customer.phone + "" : ""),
                  }))}
                value={params.customer_id}
                onChange={(e) =>
                  setParams({ ...params, customer_id: e.target.value })
                }
                onSearch={(text) => {
                  setCustomerParams({ ...customerParams, search: text });
                  if (text == "") {
                    setParams({ ...params, customer_id: null });
                  }
                }}
                apiSearch={true}
              />
              <Input
                label="Tanggal Kirim"
                placeholder="Tanggal Kirim"
                type="date"
                value={params.createdAt}
                onChange={(e) =>
                  setParams({ ...params, createdAt: e.target.value })
                }
              />
            </FormHeader>
          </Table.SearchBarWrapper>
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={true}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>SOD</Table.Heading>
              <Table.Heading>Request Date</Table.Heading>
              <Table.Heading>Requester</Table.Heading>
              <Table.Heading>Status</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {salesOrderEditRequests?.map((salesOrderEditRequest, key) => (
              <Table.BodyRow key={key}>
                <Table.Cell>
                  <p>{salesOrderEditRequest?.so_before?.[0]?.order_code}</p>
                </Table.Cell>
                <Table.Cell>
                  <Typography.Date
                    format="dd MMM yyyy"
                    size="xs"
                    date={salesOrderEditRequest.createdAt}
                  />
                </Table.Cell>
                <Table.Cell>
                  {salesOrderEditRequest?.requestor?.email}
                </Table.Cell>
                <Table.Cell>
                  {/* {salesOrderEditRequest.status} */}
                  <Badge
                    color={salesOrderEditRequest.status == 1 ? "yellow" : (salesOrderEditRequest.status == 2 ? "green" : "red")}
                  >
                    {salesOrderEditRequest.status == 1 ? "Tunggu" : (salesOrderEditRequest.status == 2 ? "Approved" : "Ditolak")}
                  </Badge>
                </Table.Cell>
                <Table.Cell>
                  <div className="flex space-x-2">
                    <div className="flex">
                      <IconButton
                        icon={PencilSquareIcon}
                        href={"/sales_order_edit_request/update/" + salesOrderEditRequest.id + "/after"}
                      />
                      <IconButton
                        icon={TrashIcon}
                        onClick={(e) => {
                          setPayloads("salesOrderEditRequest.delete", {
                            id: salesOrderEditRequest.id,
                          });
                          openModal("salesOrderEditRequest.delete");
                        }}
                      />
                    </div>
                  </div>
                </Table.Cell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table.Wrapper>
        <Table.PaginationWrapper>
          <ServerSidePaginationButtons
            paginationProps={salesOrderEditRequestPaginationProps}
            params={params}
            setParams={setParams}
          />
        </Table.PaginationWrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default SalesOrderEditRequestPage;
