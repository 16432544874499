import axios from "../axios";
import h from "../helpers";
import { clientIp } from "../api-address";

const user = {
  login: async (email, password) => {
    const { data } = await axios.post(h.baseUrl("auth"), {
      email,
      password,
      ip_address: await clientIp(),
    });
    return data;
  },
  show: async (id) => {
    const { data } = await axios.get(h.baseUrl("users/" + id));
    return data;
  },
  update: async (id, password) => {
    const { data } = await axios.put(h.baseUrl("users/" + id), {
      password,
      ip_address: await clientIp(),
    });
    return data;
  },
};

export default user;
