import clsx from 'clsx';
import style from 'style/height.module.css';

function TableWrapper({ children, asIndex = true, overflow = true }) {
  return (
    <div
      className={clsx(
        "print:h-full print:overflow-hidden pb-24",
        asIndex ? clsx(style["table-as-index-height"], "") : "",
        overflow ? "overflow-y-auto overflow-x-auto" : "overflow-visible"
      )}
    >
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        {children}
      </table>
    </div>
  );
}

export default TableWrapper;
