import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useApplicationModals } from "app/common/hooks";
import { useCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import SupplierPageTable from "./table";
import SupplierDetailSubPage from "./detail";

const breadcrumbItems = [{ label: "Supplier", href: "/supplier" }];

function useSupplierCollector() {
  const [suppliers, setSuppliers, loader, collect] = useCollector({
    modelName: "supplier",
  });

  const [query, setQuery] = useState("");
  const handleQueryChange = (e) => setQuery(e.target.value);

  useEffect(() => {
    collect({ search: query });
  }, [query]);

  return [suppliers, setSuppliers, loader, collect, query, handleQueryChange];
}

function SupplierPage() {
  const { openModal, setPayloads } = useApplicationModals();

  const [suppliers, setSuppliers, loader, collect, query, handleQueryChange] =
    useSupplierCollector();

  return (
    <MainLayout
      activeSidebarNavigation="contacts"
      activeSidebarChild="supplier"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Supplier"
      headingButtons={[
        {
          label: "Tambah Supplier",
          type: "menuButton",
          onClick: () => openModal("supplier.create"),
        },
      ]}
    >
      <AppModals
        items={["supplier.create", "supplier.delete"]}
        onSuccess={{
          "supplier.create": () => collect(),
          "supplier.delete": () => collect(),
        }}
      />
      {suppliers && (
        <Routes>
          <Route
            path="/"
            element={
              <SupplierPageTable
                suppliers={suppliers}
                onDeleteSupplierClick={(id) => {
                  setPayloads("supplier.delete", { id });
                  openModal("supplier.delete");
                }}
                query={query}
                onQueryChange={handleQueryChange}
              />
            }
          />
          <Route
            path="/detail/:id"
            element={
              <SupplierDetailSubPage
                suppliers={suppliers}
                query={query}
                onQueryChange={handleQueryChange}
              />
            }
          />
        </Routes>
      )}
    </MainLayout>
  );
}

export default SupplierPage;
