import { useApplicationModals } from "app/common/hooks";
import {
  BadgeButton,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import { Input } from "app/components/atoms/input";
import { FormHeader } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import { usePaginatedCollector } from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const breadcrumbItems = [
  { label: "Sales", href: "/sales_order" },
  { label: "Antrian Produk", href: "#" },
];

function CustomerOrderList() {
  const default_limit = process.env.REACT_APP_DEFAULT_LIMIT_QUEUE;
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();
  const typingTimer = useRef(null);
  const doneTypingInterval = 2000;

  const [code, setCode] = useState("");

  const [params, setParams] = useState({
    product_search: "",
    page: 1,
    limit: default_limit,
  });

  const [
    orderItems,
    setOrderItems,
    orderItemsLoader,
    collectOrderItems,
    orderItemPaginationProps,
  ] = usePaginatedCollector({
    modelName: "orderItem",
    methodName: "customerOrderList",
    params: params,
  });

  useEffect(() => {
    collectOrderItems(params);
  }, [params]);

  const handleKeyUp = () => {
    clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(doneTyping, doneTypingInterval);
  };

  const handleKeyDown = () => {
    clearTimeout(typingTimer.current);
  };

  const doneTyping = () => {
    let set_limit = default_limit;
    if (code === "") {
      set_limit = default_limit;
    } else {
      set_limit = 10000;
    }
    setParams({
      ...params,
      product_search: code,
      limit: set_limit,
    });
    collectOrderItems(params);
  };

  const getTotalPembelian = (item) => {
    const buying_list = item?.buying_list;
    if (!buying_list) {
      return { total: item?.total_qty, color: "green", min: 0 };
    } else {
      let total = item?.total_qty;
      let product_code = item?.product_code;
      let size_name = item?.size_name;
      let color_name = item?.color_name;

      let full_product_name = `${product_code} ${color_name} ${size_name}`;

      let buying_list_array = buying_list.split(",");
      buying_list_array.forEach((buying_item) => {
        let buying_item_array = buying_item.split(":");
        let buying_product_name = buying_item_array[0].trim();
        let buying_qty = buying_item_array[1].trim().replace("pcs", "");
        if (buying_product_name === full_product_name) {
          total = Number(total) + Number(buying_qty);
        }
      });

      return {
        total,
        color: Number(total) >= Number(item?.total_qty) ? "green" : "red",
        min: Number(item?.total_qty) - Number(total),
      };
    }
  };

  return (
    <MainLayout
      activeSidebarNavigation="sales"
      activeSidebarChild="product_queue_grid"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Daftar Antrian Produk"
      headingButtons={[]}
    >
      <FormHeader appearance="none">
        <Input
          label="Nama Product"
          placeholder="Cari produk..."
          type="text"
          value={code}
          onChange={async (e) => setCode(e.target.value)}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
        />
      </FormHeader>
      <div className="grid grid-cols-2 gap-x-4 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:flex xl:flex-wrap xl:gap-x-8 px-4 pb-8">
        {orderItems?.map((item, key) => (
          <div
            key={key}
            onClick={(e) => {
              navigate(
                "/sales_order/antrian_produk/" +
                  item.product_code +
                  "/" +
                  item.color_name +
                  "/" +
                  item.size_name +
                  "/" +
                  item.product_id +
                  "/" +
                  item.color_variant_id +
                  "/" +
                  item.size_variant_id +
                  "/" +
                  Number(getTotalPembelian(item)?.min)
              );
            }}
            className="group relative border border-gray-200 rounded-md"
          >
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80 xl:w-64">
              <img
                src={`${process.env.REACT_APP_AWS_LINK_URL}${item?.image_url}`}
                alt={item?.product_code}
                className="h-full w-full object-contain object-center lg:h-full lg:w-full"
              />
            </div>
            <div className="mt-2 flex justify-between px-2 pb-1">
              <div>
                <h3 className="text-sm text-gray-700">
                  <a
                    href={
                      "/sales_order/antrian_produk/" +
                      item.product_code +
                      "/" +
                      item.color_name +
                      "/" +
                      item.size_name +
                      "/" +
                      item.product_id +
                      "/" +
                      item.color_variant_id +
                      "/" +
                      item.size_variant_id +
                      "/" +
                      Number(getTotalPembelian(item)?.min)
                    }
                  >
                    <span aria-hidden="true" className="absolute inset-0" />
                    {item?.product_code}
                  </a>
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {item?.supplier_name}
                </p>
              </div>
              <div>
                <p className="text-sm tracking-tighter font-medium text-gray-900 text-right">
                  {item?.size_name + " - " + item?.color_name}
                </p>
                <p className="text-right text-sm">
                  Orderan: {Number(item?.total_qty)}
                </p>
                <p className="text-right text-sm">
                  Sudah dibeli:{" "}
                  <span style={{ color: getTotalPembelian(item)?.color }}>
                    <Typography.Currency
                      number={Number(getTotalPembelian(item)?.total)}
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Table.PaginationWrapper>
        <ServerSidePaginationButtons
          paginationProps={orderItemPaginationProps}
          params={params}
          setParams={setParams}
        />
      </Table.PaginationWrapper>
    </MainLayout>
  );
}

export default CustomerOrderList;
