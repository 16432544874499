
function DirectoryBody({ children }) {
  return (
    <div className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700 text-gray-900 dark:text-gray-100 print:divide-gray-50 pb-24">
      {
        children
      }
    </div>
  );
}

export default DirectoryBody;
