import { useApplicationModals } from "app/common/hooks";
import { Badge } from "app/components/atoms/badge";
import {
  BadgeButton,
  Button,
  ServerSidePaginationButtons,
} from "app/components/atoms/button";
import { FormHeader } from "app/components/atoms/layout";
import { Select, SelectSearch } from "app/components/atoms/select";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import PDFGeneratorComponent from "app/components/organisms/pdf/pdfGenerator";
import {
  useCollector,
  usePaginatedCollector,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SearchInput } from "app/components/atoms/input";

const breadcrumbItems = [
  { label: "Gudang", href: "#" },
  { label: "Packing", href: "#" },
];

function PackingOrderPage() {
  const navigate = useNavigate();

  const { openModal, setPayloads } = useApplicationModals();
  const [packingOrder, setPackingOrder] = useState({});
  const [productSearch, setProductSearch] = useState("");

  const [params, setParams] = useState({
    search: "",
    product_search: "",
    createdAt: null,
    customer_id: null,
    product_id: null,
    status: 2,
    is_save: null,
    page: 1,
    limit: 50,
  });

  const [
    deliveryOrders,
    setPurchaseOrders,
    deliveryOrdersLoader,
    collectDeliveryOrder,
    deliveryOrderPaginationProps,
  ] = usePaginatedCollector({ modelName: "deliveryOrder", params: params });

  const [customerParams, setCustomerParams] = useState({
    search: "",
    page: 1,
    limit: Number(process.env.REACT_APP_CUSTOMER_LIMIT),
  });

  const [customers, setMCustomers, customersLoader, collectMCustomers] =
    useCollector({ modelName: "customer", params: customerParams });

  useEffect(() => {
    collectMCustomers(customerParams);
  }, [customerParams]);

  const statusDO = (status) => {
    switch (status) {
      case 1:
        return "Tunggu Stok";
        break;
      case 2:
        return "Siap Packing";
        break;
      case 3:
        return "Siap Dikirim";
        break;
      case 4:
        return "Sudah Dikirim";
        break;
      case 5:
        return "Pending";
        break;

      default:
        break;
    }
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const typingTimer = useRef(null);
  const doneTypingInterval = 2000;

  const handleKeyUp = () => {
    clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(doneTyping, doneTypingInterval);
  };

  const handleKeyDown = () => {
    clearTimeout(typingTimer.current);
  };

  const doneTyping = () => {
    console.log("done typing");
    setParams({ ...params, product_search: productSearch });
  };

  const handleCheckboxClick = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      );
    }
  };

  const handleSelectAll = () => {
    setSelectAll(true);
    setSelectedItems(deliveryOrders);
  };

  const handleDeselectAll = () => {
    setSelectAll(false);
    setSelectedItems([]);
  };

  const handleSelectAllCheckboxClick = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      handleSelectAll();
    } else {
      handleDeselectAll();
    }
  };

  return (
    <MainLayout
      activeSidebarNavigation="warehouse"
      activeSidebarChild="packing"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Daftar Packing"
    >
      <AppModals
        items={["deliveryOrder.packing"]}
        onSuccess={{
          "deliveryOrder.packing": () => collectDeliveryOrder(),
        }}
      />
      <AppModals
        items={["deliveryOrder.sticker"]}
        onSuccess={{
          "deliveryOrder.sticker": () => collectDeliveryOrder(),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            <FormHeader appearance="none">
              <SelectSearch
                label="Customer"
                placeholder="Customer"
                options={customers
                  ?.sort(function (a, b) {
                    if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase())
                      return -1;
                    if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase())
                      return 1;
                    return 0;
                  })
                  .map((customer) => ({
                    value: customer.id,
                    label:
                      customer.firstName +
                      " " +
                      customer.middleName +
                      " " +
                      customer.lastName +
                      (customer.nickName
                        ? " (" + customer.nickName + ")"
                        : "") +
                      (customer.phone ? " - " + customer.phone + "" : ""),
                  }))}
                value={params.customer_id}
                onChange={(e) =>
                  setParams({ ...params, customer_id: e.target.value })
                }
                onSearch={(text) => {
                  setCustomerParams({ ...customerParams, search: text });
                  if (text == "") {
                    setParams({ ...params, customer_id: null });
                  }
                }}
                apiSearch={true}
              />
              <SearchInput
                placeholder="Cari Produk"
                type="text"
                value={params.product_search}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                onChange={(e) => setProductSearch(e.target.value)}
              />
              {/* <Select
                label="Filter Status Kirim"
                placeholder=""
                options={[
                  {
                    value: null,
                    label: "Semua Status Kirim",
                  },
                  {
                    value: 1,
                    label: "Tunggu Stok",
                  },
                  {
                    value: 2,
                    label: "Siap Packing",
                  },
                  {
                    value: 3,
                    label: "Siap Dikirim",
                  },
                  {
                    value: 4,
                    label: "Sudah Dikirim",
                  },
                  {
                    value: 5,
                    label: "Pending",
                  },
                ]}
                value={params.status}
                onChange={(e) =>
                  setParams({ ...params, status: e.target.value })
                }
              /> */}
              {/* <Select
                label="Filter Status Beli"
                placeholder=""
                options={[
                  {
                    value: null,
                    label: "Semua Status Pembelian",
                  },
                  {
                    value: true,
                    label: "Sudah dibeli",
                  },
                  {
                    value: false,
                    label: "Belum dibeli",
                  },
                ]}
                value={params.is_save}
                onChange={(e) =>
                  setParams({ ...params, is_save: e.target.value })
                }
              /> */}
              <div className="flex gap-2 items-center">
                <div className="flex items-center gap-2 text-gray-700 font-light text-sm">
                  <input
                    name="chkSelectAll"
                    id="chkSelectAll"
                    type="checkbox"
                    onChange={handleSelectAllCheckboxClick}
                  />
                  <label htmlFor="chkSelectAll">
                    {selectAll ? "Deselect All" : "Select all"}
                  </label>
                </div>
                <PDFGeneratorComponent data={selectedItems} />
              </div>
            </FormHeader>
          </Table.SearchBarWrapper>
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={true}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading></Table.Heading>
              <Table.Heading>No.</Table.Heading>
              <Table.Heading>Order</Table.Heading>
              <Table.Heading>Customer</Table.Heading>
              <Table.Heading>Status Kirim</Table.Heading>
              <Table.Heading>Status Beli</Table.Heading>
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {deliveryOrders
              ?.filter((deliveryOrder) => deliveryOrder.SalesOrder !== null && deliveryOrder.status === 2)
              .map((deliveryOrder, key) => (
                <Table.BodyRow key={key}>
                  <Table.Cell>
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(deliveryOrder)}
                      onChange={(event) =>
                        handleCheckboxClick(event, deliveryOrder)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <p>{deliveryOrder.code}</p>
                    <Typography.Date size="xs" date={deliveryOrder.createdAt} />
                  </Table.Cell>
                  <Table.Cell>
                    <BadgeButton
                      href={
                        "/sales_order/update/" + deliveryOrder?.sales_order_id
                      }
                    >
                      {deliveryOrder?.SalesOrder?.order_code}
                    </BadgeButton>
                    <p></p>
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      {deliveryOrder.MCustomer?.firstName}{" "}
                      {deliveryOrder.MCustomer?.middleName}{" "}
                      {deliveryOrder.MCustomer?.lastName}
                      {deliveryOrder.delivery_to !==
                        deliveryOrder.customer_id &&
                        deliveryOrder.MCustomer?.nickName !== null &&
                        deliveryOrder.MCustomer?.nickName !== "" &&
                        " (" + deliveryOrder.MCustomer?.nickName + ")"}
                    </p>
                    <p className="text-gray-500 font-light text-sm">
                      {deliveryOrder.MCustomer?.phone !== null &&
                        deliveryOrder.MCustomer?.phone !== "" &&
                        deliveryOrder.MCustomer?.phone}
                    </p>
                  </Table.Cell>

                  <Table.Cell>
                    {deliveryOrder.Invoice != null ? (
                      <BadgeButton
                        onClick={(e) =>
                          navigate(
                            "/invoice/detail/" + deliveryOrder?.invoice_id
                          )
                        }
                      >
                        Invoice
                      </BadgeButton>
                    ) : (
                      <Badge
                        color={
                          deliveryOrder.status === 1
                            ? "gray"
                            : deliveryOrder.status === 2
                            ? "yellow"
                            : deliveryOrder.status === 3
                            ? "blue"
                            : deliveryOrder.status === 4
                            ? "green"
                            : "gray"
                        }
                      >
                        {statusDO(deliveryOrder.status)}
                      </Badge>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Badge
                      color={
                        deliveryOrder.status != 1
                          ? "green"
                          : deliveryOrder.is_save
                          ? "green"
                          : "yellow"
                      }
                    >
                      {deliveryOrder.status != 1
                        ? "Sudah dibeli"
                        : deliveryOrder.is_save
                        ? "Sudah dibeli"
                        : "Belum dibeli"}
                    </Badge>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex space-x-2">
                      <div className="flex gap-1">
                        <BadgeButton
                          color="green"
                          onClick={(e) => {
                            setPayloads("deliveryOrder.packing", deliveryOrder);
                            openModal("deliveryOrder.packing");
                          }}
                        >
                          {deliveryOrder.status === 2
                            ? "Packing"
                            : deliveryOrder.status === 3
                            ? "Kirim"
                            : deliveryOrder.status === 4
                            ? "Lihat"
                            : "Lihat"}
                        </BadgeButton>
                        <BadgeButton
                          color="green"
                          onClick={(e) => {
                            setPayloads("deliveryOrder.sticker", deliveryOrder);
                            openModal("deliveryOrder.sticker");
                          }}
                        >
                          Stiker
                        </BadgeButton>
                        {/* <IconButton
                        icon={TrashIcon}
                        onClick={(e) => {
                          setPayloads("deliveryOrder.delete", {
                            id: deliveryOrder.id,
                          });
                          openModal("deliveryOrder.delete");
                        }}
                      /> */}
                      </div>
                    </div>
                  </Table.Cell>
                </Table.BodyRow>
              ))}
          </Table.Body>
        </Table.Wrapper>
        <Table.PaginationWrapper>
          <ServerSidePaginationButtons
            paginationProps={deliveryOrderPaginationProps}
            params={params}
            setParams={setParams}
          />
        </Table.PaginationWrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default PackingOrderPage;
