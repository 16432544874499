import user from "./models/user";
import customer from "./models/customer";
import colorVariant from "./models/colorVariant";
import sizeVariant from "./models/sizeVariant";
import productVariant from "./models/productVariant";
import product from "./models/product";
import supplier from "./models/supplier";
import rajaOngkir from "./models/rajaOngkir";
import category from "./models/category";
import salesOrder from "./models/salesOrder";
import salesOrderEditRequest from "./models/salesOrderEditRequest";
import address from "./models/address";
import invoice from "./models/invoice";
import payment from "./models/payment";
import report from "./models/report";
import purchaseOrder from "./models/purchaseOrder";
import deliveryOrder from "./models/deliveryOrder";
import receiveOrder from "./models/receiveOrder";
import purchaseOrderItem from "./models/purchaseOrderItem";
import receiveOrderItem from "./models/receiveOrderItem";
import uploadImage from "./models/upload";
import credit from "./models/credit";
import requestApprove from "./models/requestApprove";
import orderItem from "./models/orderItem";

export default {
  user,
  customer,
  category,
  colorVariant,
  sizeVariant,
  productVariant,
  product,
  supplier,
  rajaOngkir,
  salesOrder,
  salesOrderEditRequest,
  requestApprove,
  address,
  invoice,
  payment,
  report,
  purchaseOrder,
  deliveryOrder,
  receiveOrder,
  purchaseOrderItem,
  receiveOrderItem,
  uploadImage,
  credit,
  orderItem,
};

export {
  user,
  customer,
  category,
  colorVariant,
  sizeVariant,
  productVariant,
  product,
  supplier,
  rajaOngkir,
  salesOrder,
  salesOrderEditRequest,
  requestApprove,
  address,
  invoice,
  payment,
  report,
  purchaseOrder,
  deliveryOrder,
  receiveOrder,
  purchaseOrderItem,
  receiveOrderItem,
  uploadImage,
  credit,
  orderItem,
};
