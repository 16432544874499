import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useApplicationModals } from "app/common/hooks";
import {
  BadgeButton,
  Button,
  DottedOutlineButton,
  IconButton,
} from "app/components/atoms/button";
import { FormHeader, FormSummary } from "app/components/atoms/layout";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import { InputMolecule } from "app/components/molecules/form";
import ImageWithPreview from "app/components/molecules/imageWithPreview";
import { ModalMolecule } from "app/components/molecules/modal";
import {
  SelectMolecule,
  SelectSearchMolecule,
  ProductSelectSearchMolecule,
} from "app/components/molecules/select";
import api from "app/integration/api";
import {
  useCollector,
  useCreateForm,
  usePaginatedCollector,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import clsx from "clsx";
import { FieldArray, Formik, getIn } from "formik";
import str from "lib/str";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "style/height.module.css";
import {
  filter as _filter,
  findWhere as _findWhere,
  flatten as _flatten,
  where as _where,
  filter,
} from "underscore";
import * as Yup from "yup";
import { isEmpty } from "underscore";

const breadcrumbItems = [
  { label: "Sales", href: "/sales_order" },
  { label: "Order", href: "/sales_order" },
  { label: "Tambah Order", href: "#" },
];

function CreateSalesOrderPage() {
  const navigate = useNavigate();
  let isError = false;

  const handleSuccess = (res) => {
    console.log(res);
    navigate("/sales_order/update/" + res.data.id);
  };

  const [isLoading, setIsloading] = useState(false);
  const [duplicateOrder, setDuplicateOrder] = useState(false);
  const [confirmedDuplicateOrder, setConfirmedDuplicateOrder] = useState(false);

  const [showInvoice, setShowInvoice] = useState(false);
  const [delivery_fee, setDeliveryFee] = useState(0);
  const [needUpdateDeliveryFee, setNeedUpdateDeliveryFee] = useState(true);

  const [salesOrders, setSalesOrders, salesOrdersLoader, collectSalesOrders] =
    useCollector({ modelName: "salesOrder" });

  const [productParams, setProductParams] = useState({
    search: "",
    product_search: "",
    order: "code",
    sort: "asc",
    status: null,
    customer_id: null,
    page: 1,
    limit: 50,
  });

  useEffect(() => {
    collectProducts(productParams);
  }, [productParams]);

  const [
    products,
    setProducts,
    productsLoader,
    collectProducts,
    productPaginationProps,
  ] = usePaginatedCollector({ modelName: "product", params: productParams });

  const [invoices, setInvoices, invoicesLoader, collectInvoices] = useCollector(
    { modelName: "invoice" }
  );

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // console.log(today);

  const duplicateProductCheck = (products) => {
    // console.log("products: ", products);
    // check for duplicate object
    const seen = new Set();
    for (const product of products) {
      if (isEmpty(product)) continue;
      const key = `${product.product_id}_${product.size_variant_id}_${product.color_variant_id}`;
      if (seen.has(key)) {
        // console.log("duplicate found!");
        return false;
      }
      seen.add(key);
    }
    return true;
  };

  const { initialValues, validationSchema, onSubmit } = useCreateForm({
    initialValues: {
      customer_id: null,
      address_id: null,
      delivery_fee: 0,
      note_order: "",
      courier_package: "",
      items: [
        {
          product_id: null,
          size_variant_id: null,
          color_variant_id: null,
          qty: 1,
          unit_price: 0,
          discount_reseller: 0,
          weight: 0,
        },
      ],
    },
    validationSchema: Yup.object().shape({
      customer_id: Yup.number().required("Customer harus diisi"),
      address_id: Yup.number().required("Alamat harus diisi"),
      delivery_fee: Yup.number(),
      note_order: Yup.string(),
      items: Yup.array()
        .of(
          Yup.object().shape({
            product_id: Yup.number().required("Produk harus diisi"),
            size_variant_id: Yup.number().required("Size harus diisi"),
            color_variant_id: Yup.number().required("Warna harus diisi"),
            qty: Yup.number().required("Qty harus diisi"),
            unit_price: Yup.number().required("Harga harus diisi"),
            discount_reseller: Yup.number()
              .min(0, "Diskon 0-" + process.env.REACT_APP_MAX_DISCOUNT)
              .max(
                Number(process.env.REACT_APP_MAX_DISCOUNT),
                "Diskon 0-" + process.env.REACT_APP_MAX_DISCOUNT
              ),
          })
        )
        .min(1, "Minimal harus ada 1 Item yang dibeli.")
        .test(
          "Unique",
          "Terdapat produk dengan varian yang sama. Jika varian sama persis, harap menambah melalui QTY.",
          (values) => {
            return duplicateProductCheck(values);
          }
        ),
    }),
    onSubmit: async (values) => {
      if (needUpdateDeliveryFee) {
        setPayloads("errorMessage.view", {
          title: "Update Sales Order Error",
          message: "Harap kalkulasi ulang ongkir terlebih dahulu.",
        });
        openModal("errorMessage.view");
        isError = true;
      } else {
        try {
          // const delivery_date = new Date(values.delivery_date).toISOString();
          const filteredSalesOrders = _where(salesOrders, {
            // delivery_date: delivery_date,
            customer_id: values.customer_id,
            delivery_to: values.deliveryTo,
            address_id: values.address_id,
          });

          // Ada order yang mirip gak?
          if (filteredSalesOrders.length > 0) {
            setDuplicateOrder(true);

            // User kalau belum konfirmasi bikin order yang sama, jangan bikin order nya
            if (!confirmedDuplicateOrder) {
              return;
            }
          }

          setIsloading(true);
          let response = await api.salesOrder.store(
            values.customer_id,
            values.address_id,
            values.note_order,
            delivery_fee,
            values.courier_package,
            values.items
              .filter((orderDetail) => !isEmpty(orderDetail))
              .map((orderDetail) => ({
                product_id: orderDetail.product_id,
                size_variant_id: orderDetail.size_variant_id,
                color_variant_id: orderDetail.color_variant_id,
                qty: orderDetail.qty,
                unit_price: orderDetail.unit_price,
                discount_reseller: orderDetail.discount_reseller,
                weight: orderDetail.weight,
              }))
          );
          isError = false;
          return response;
        } catch (error) {
          if (error?.response?.data?.message) {
            setPayloads("errorMessage.view", {
              title: "Create Sales Order",
              message: error?.response?.data?.message,
            });
            isError = true;
            openModal("errorMessage.view");
            setIsloading(false);
          } else {
            setPayloads("errorMessage.view", {
              title: "Create Sales Order",
              message: "Server Error",
            });
            setIsloading(false);
            navigate("/sales_order");
          }
        }
      }
    },
    onSuccess: handleSuccess,
  });

  const [customerParams, setCustomerParams] = useState({
    search: "",
    page: 1,
    limit: Number(process.env.REACT_APP_CUSTOMER_LIMIT),
  });
  const [customers, setMCustomers, customersLoader, collectMCustomers] =
    useCollector({ modelName: "customer", params: customerParams });

  useEffect(() => {
    collectMCustomers(customerParams);
  }, [customerParams]);

  // const [dailyMenus] = useCollector({ modelName: "dailyMenu" });
  const [variants] = useCollector({ modelName: "variant" });

  const { openModal, setPayloads } = useApplicationModals();
  const [currentErrors, setCurrentErrors] = useState([]);

  const [selectedCourier, setSelectedCourier] = useState("");

  function runValidations(values) {
    validationSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
        setCurrentErrors([]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.name); // ValidationError
        console.log(err.errors);
        setCurrentErrors(err.errors);

        setPayloads("errorMessage.view", {
          title: "Tambah Order Error",
          data: err.errors,
        });
        openModal("errorMessage.view");
      });
  }

  const localHelpers = {
    // unselectedDailyMenusWithCurrentSelectedValue: (
    //   items,
    //   currentSelectedDailyMenuId,
    //   requestedDate
    // ) => {
    //   const selectedDailyMenuIds = items?.map(
    //     (orderDetail) => orderDetail.dailyMenuId
    //   );
    //   const filteredDailyMenus = dailyMenus;
    //   return _filter(
    //     filteredDailyMenus,
    //     (dailyMenu) =>
    //       str.standardDate(dailyMenu.delivery_date) ===
    //       str.standardDate(requestedDate)
    //   );
    // },
    variantItemsByVariantId: (variantId) => {
      const variantCategories = _findWhere(variants, {
        id: variantId,
      })?.VariantCategories;
      const variantItems = _flatten(
        variantCategories?.map((variantCategory) => variantCategory.Variants)
      );
      return variantItems;
    },
    variantItemById: (variantItemId) => {
      const variantItems = _flatten(
        variants.map((variant) => {
          return _flatten(
            variant.VariantCategories.map(
              (variantCategory) => variantCategory.Variants
            )
          );
        })
      );
      return _findWhere(variantItems, { id: variantItemId });
    },
    subtotal: (orderItems) => {
      // console.log("order items:", orderItems);
      let subtotal = orderItems
        .map(
          (orderItem) =>
            +orderItem.qty *
            (+orderItem.unit_price - orderItem.discount_reseller)
        )
        .reduce((partialSum, a) => partialSum + a, 0);

      return +subtotal;
    },
    totalWeight: (orderItems) => {
      let totalWeightOrder = orderItems
        .map((orderItem) => +orderItem.qty * +orderItem.weight)
        .reduce((partialSum, a) => partialSum + a, 0);

      // console.log("Weight before roundup: ", totalWeightOrder);
      // Round up by 1000
      totalWeightOrder = Math.ceil(totalWeightOrder / 1000) * 1000;
      // console.log("Weight after roundup: ", totalWeightOrder);

      return +totalWeightOrder;
    },
  };

  function customerInvoices(customer_id) {
    return _where(invoices, { customer_id: customer_id });
  }

  function getUniqueData(product, product_id, variant, selected_variant_id) {
    let uniqueData = {};
    let isDisabled = false;

    product?.ProductVariants?.forEach((item) => {
      if (variant === "color" && selected_variant_id > 0) {
        if (
          product?.ProductVariants.find(
            (option) =>
              option.size.id === selected_variant_id &&
              option.color.id === item[`${variant}`].id
          ) === undefined
        ) {
          isDisabled = true;
        } else {
          isDisabled = false;
        }
      }
      if (variant === "size" && selected_variant_id > 0) {
        if (
          product?.ProductVariants?.find(
            (option) =>
              option.color.id === selected_variant_id &&
              option.size.id === item[`${variant}`].id
          ) === undefined
        ) {
          isDisabled = true;
        } else {
          isDisabled = false;
        }
      }
      uniqueData[item[`${variant}`].name] = {
        id: item[`${variant}`].id,
        name: item[`${variant}`].name,
        disabled: isDisabled,
      };
    });

    let convertData = Object.entries(uniqueData).map((item) => {
      return {
        value: item[1].id,
        label: item[1].name,
        disabled: item[1].disabled,
      };
    });

    // console.log("convertData: ", variant, " : ", convertData);

    return convertData;
  }

  async function getCustomerAddress(address_id) {
    const response = await api.address.show(address_id);
    return response?.data?.city_id;
  }

  async function getDeliveryFee(address_id, items, type) {
    let destination = await getCustomerAddress(address_id);
    let totalWeightOrder = items
      .map((orderItem) => +orderItem.qty * +orderItem.weight)
      .reduce((partialSum, a) => partialSum + a, 0);

    // Round up by 1000
    totalWeightOrder = Math.ceil(totalWeightOrder / 1000) * 1000;

    if (totalWeightOrder <= 0) {
      setPayloads("errorMessage.view", {
        title: "Get Ongkir Error",
        message:
          "Tidak dapat kalkulasi ongkir jika berat produk 0. Harap set berat produk dengan benar.",
      });
      isError = true;
      openModal("errorMessage.view");
      return;
    }

    if (destination && totalWeightOrder > 0) {
      const response = await api.rajaOngkir.domestic(
        destination,
        totalWeightOrder
      );

      if (response && response.data.length > 0) {
        let filtered = response.data[0]?.costs.filter(
          (i) => i.service == type
        )[0];

        if (!filtered) {
          setDeliveryFee(0);
          setNeedUpdateDeliveryFee(true);
          alert("Kurir tidak tersedia");
        } else {
          setDeliveryFee(filtered?.cost[0]?.value);
          setNeedUpdateDeliveryFee(false);
        }
      }
    }
  }

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleProductSelected = (selectedProduct, key) => {
    // Save selectedProduct into an array of selectedProducts based on index key
    setSelectedProducts((prevSelectedProducts) => {
      const updatedSelectedProducts = [...prevSelectedProducts];
      updatedSelectedProducts[key] = selectedProduct;
      return updatedSelectedProducts;
    });
  };

  const updateProductPrice = (selectedProduct, key, values) => {
    values.items[`${key}`].unit_price = selectedProduct?.price;
    values.items[`${key}`].weight = selectedProduct?.weight;
    values.items[`${key}`].imageUrl = selectedProduct?.imageUrl;
  };

  const displayName = (customers) => {
    const customerList = [];
    customers?.map((customer) => {
      let label = "";
      if (customer?.firstName) label += customer?.firstName;
      if (customer?.middleName) label += ` ${customer?.middleName}`;
      if (customer?.lastName) label += ` ${customer?.lastName}`;
      if (customer?.nickName) label += ` (${customer?.nickName})`;
      if (customer?.phone) label += ` - ${customer?.phone}`;

      customerList.push({
        value: customer.id,
        label,
      });
    });
    return customerList;
  };

  useEffect(() => {
    console.log("selectedProducts: ", selectedProducts);
  }, [selectedProducts]);

  return (
    <MainLayout
      activeSidebarNavigation="order"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Tambah Order"
      headingButtons={[]}
    >
      <AppModals
        items={["errorMessage.view"]}
        onSuccess={{
          "errorMessage.view": () => fetch(),
        }}
      />
      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        enableReinitialize={false}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className={clsx(
              "px-2 overflow-y-auto",
              style["main-content-height"]
            )}
            onSubmit={handleSubmit}
          >
            <FormHeader>
              <SelectSearchMolecule
                label="Customer"
                name="customer_id"
                options={displayName(customers)}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("deliveryTo", e.target.value);
                  const customer = _findWhere(customers, {
                    id: e.target.value,
                  });
                  setFieldValue(
                    "address_id",
                    customer?.customer_address[0]?.id
                  );
                  setFieldValue(
                    "note_order",
                    customer?.customer_address[0]?.note_order
                  );
                  setFieldValue(
                    "deliveryTime",
                    customer?.customer_address[0]?.delivery_time
                  );
                  setFieldValue(
                    "parkingFee",
                    customer?.customer_address[0]?.parking_fee
                  );
                  setNeedUpdateDeliveryFee(true);
                }}
                onSearch={(text) => {
                  setCustomerParams({ ...customerParams, search: text });
                }}
                apiSearch={true}
                onBlur={handleBlur}
                value={values.customer_id}
                errorMessage={
                  errors.customer_id &&
                  touched.customer_id &&
                  errors.customer_id
                }
              />
              <SelectMolecule
                label="Alamat Kirim"
                name="address_id"
                options={_findWhere(customers, {
                  id: values.deliveryTo,
                })?.customer_address?.map((address) => ({
                  value: address.id,
                  label: address.desc,
                }))}
                onChange={(e) => {
                  handleChange(e);
                  setNeedUpdateDeliveryFee(true);
                }}
                onBlur={handleBlur}
                value={values.address_id}
                errorMessage={
                  errors.address_id && touched.address_id && errors.address_id
                }
              />
              <SelectMolecule
                label="Kurir"
                name="courier_package"
                options={[
                  {
                    value: "JAGOPACK",
                    label: "JAGOPACK",
                  },
                  {
                    value: "REGPACK",
                    label: "REGPACK",
                  },
                  {
                    value: "BIGPACK",
                    label: "BIGPACK",
                  },
                  {
                    value: "BOSSPACK",
                    label: "BOSSPACK",
                  },
                ]}
                onChange={(e) => {
                  handleChange(e);
                  getDeliveryFee(
                    values.address_id,
                    values.items,
                    e.target.value
                  );
                  setSelectedCourier(e.target.value);
                  setNeedUpdateDeliveryFee(true);
                }}
              />
            </FormHeader>

            <div className="py-2 overflow-visible">
              <div className="w-full">
                <FieldArray name="items">
                  {(arrayHelpers) => (
                    <div className="">
                      <Table.Wrapper asIndex={false} overflow={false}>
                        <Table.Header>
                          <Table.HeaderRow>
                            <Table.Heading>Product</Table.Heading>
                            <Table.Heading>Size</Table.Heading>
                            <Table.Heading>Warna</Table.Heading>
                            {/* <Table.Heading>Catatan</Table.Heading> */}
                            <Table.Heading>Qty</Table.Heading>
                            <Table.Heading>
                              <p className="text-right">Harga Satuan</p>
                            </Table.Heading>
                            <Table.Heading>
                              <p className="text-right">Diskon</p>
                            </Table.Heading>
                            <Table.Heading>
                              <p className="text-right">Subtotal</p>
                            </Table.Heading>
                            <Table.Heading></Table.Heading>
                          </Table.HeaderRow>
                        </Table.Header>
                        <Table.Body>
                          {values.items?.map((orderDetail, key) => (
                            <Table.BodyRow key={key}>
                              <Table.Cell className="">
                                <div className="flex gap-2 items-center">
                                  {orderDetail?.imageUrl && (
                                    <ImageWithPreview
                                      size="lg"
                                      src={`${process.env.REACT_APP_AWS_LINK_URL}${orderDetail?.imageUrl}`}
                                    ></ImageWithPreview>
                                  )}
                                  <div className="w-4/5">
                                    <ProductSelectSearchMolecule
                                      hideLabel={true}
                                      name={`items[${key}].product_id`}
                                      onChange={(e) => {
                                        // reset varian size dan color
                                        values.items[
                                          `${key}`
                                        ].color_variant_id = null;
                                        values.items[`${key}`].size_variant_id =
                                          null;

                                        handleChange(e);

                                        // karena produk diganti, harus kalkulasi ongkir ulang
                                        setNeedUpdateDeliveryFee(true);
                                      }}
                                      apiSearch={true}
                                      onBlur={handleBlur}
                                      value={orderDetail.product_id}
                                      errorMessage={
                                        getIn(
                                          errors,
                                          `items[${key}].product_id`
                                        ) &&
                                        getIn(
                                          touched,
                                          `items[${key}].product_id`
                                        ) &&
                                        getIn(
                                          errors,
                                          `items[${key}].product_id`
                                        )
                                      }
                                      absolute={true}
                                      onProductSelected={(selectedProduct) => {
                                        handleProductSelected(
                                          selectedProduct,
                                          key
                                        );
                                        updateProductPrice(
                                          selectedProduct,
                                          key,
                                          values
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="w-24">
                                <SelectMolecule
                                  hideLabel={true}
                                  name={`items[${key}].size_variant_id`}
                                  options={getUniqueData(
                                    selectedProducts[key],
                                    values.items[`${key}`].product_id,
                                    "size",
                                    values.items[`${key}`].color_variant_id
                                  )}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  value={orderDetail.size_variant_id}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `items[${key}].size_variant_id`
                                    ) &&
                                    getIn(
                                      touched,
                                      `items[${key}].size_variant_id`
                                    ) &&
                                    getIn(
                                      errors,
                                      `items[${key}].size_variant_id`
                                    )
                                  }
                                  absolute={true}
                                />
                              </Table.Cell>
                              <Table.Cell className="max-w-xs">
                                <SelectMolecule
                                  hideLabel={true}
                                  name={`items[${key}].color_variant_id`}
                                  options={getUniqueData(
                                    selectedProducts[key],
                                    values.items[`${key}`].product_id,
                                    "color",
                                    values.items[`${key}`].size_variant_id
                                  )}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  value={orderDetail.color_variant_id}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `items[${key}].color_variant_id`
                                    ) &&
                                    getIn(
                                      touched,
                                      `items[${key}].color_variant_id`
                                    ) &&
                                    getIn(
                                      errors,
                                      `items[${key}].color_variant_id`
                                    )
                                  }
                                  absolute={true}
                                />
                              </Table.Cell>
                              {/* <Table.Cell>
                                <InputMolecule
                                  hideLabel={true}
                                  type="text"
                                  name={`items[${key}].notes`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.notes}
                                  errorMessage={
                                    getIn(errors, `items[${key}].notes`) &&
                                    getIn(touched, `items[${key}].notes`) &&
                                    getIn(errors, `items[${key}].notes`)
                                  }
                                />
                              </Table.Cell> */}
                              <Table.Cell className="w-24">
                                <InputMolecule
                                  hideLabel={true}
                                  type="number"
                                  name={`items[${key}].qty`}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setNeedUpdateDeliveryFee(true);
                                  }}
                                  onBlur={handleBlur}
                                  value={orderDetail.qty}
                                  errorMessage={
                                    getIn(errors, `items[${key}].qty`) &&
                                    getIn(touched, `items[${key}].qty`) &&
                                    getIn(errors, `items[${key}].qty`)
                                  }
                                  align="right"
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <p className="text-right">
                                  <Typography.Currency
                                    number={orderDetail.unit_price}
                                  />
                                </p>
                              </Table.Cell>
                              <Table.Cell className="w-32">
                                <InputMolecule
                                  hideLabel={true}
                                  type="text"
                                  name={`items[${key}].discount_reseller`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={orderDetail.discount_reseller}
                                  errorMessage={
                                    getIn(
                                      errors,
                                      `items[${key}].discount_reseller`
                                    ) &&
                                    getIn(
                                      touched,
                                      `items[${key}].discount_reseller`
                                    ) &&
                                    getIn(
                                      errors,
                                      `items[${key}].discount_reseller`
                                    )
                                  }
                                  align="right"
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <p className="text-right">
                                  <Typography.Currency
                                    number={
                                      orderDetail.qty *
                                      (orderDetail.unit_price -
                                        orderDetail.discount_reseller)
                                    }
                                  />
                                </p>
                              </Table.Cell>
                              <Table.Cell>
                                <IconButton
                                  icon={TrashIcon}
                                  onClick={(e) => {
                                    delete values.items[key];

                                    setSelectedProducts(
                                      (prevSelectedProducts) => {
                                        let updatedSelectedProducts = [
                                          ...prevSelectedProducts,
                                        ];
                                        updatedSelectedProducts[key] = {};
                                        return updatedSelectedProducts;
                                      }
                                    );
                                  }}
                                />
                              </Table.Cell>
                            </Table.BodyRow>
                          ))}
                        </Table.Body>
                      </Table.Wrapper>
                      <div>
                        <div className="">
                          <DottedOutlineButton
                            type="button"
                            onClick={(e) =>
                              arrayHelpers.push({
                                product_id: null,
                                size_variant_id: null,
                                color_variant_id: null,
                                qty: 1,
                                unit_price: 0,
                                discount_reseller: 0,
                                weight: 0,
                              })
                            }
                          >
                            Tambah Item
                          </DottedOutlineButton>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
                <FormSummary>
                  <div className="flex-grow w-full md:max-w-lg order-last md:order-first flex flex-col justify-between gap-2">
                    <InputMolecule
                      label="Catatan Pengiriman"
                      type="text"
                      name="note_order"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.note_order}
                      errorMessage={
                        errors.note_order &&
                        touched.note_order &&
                        errors.note_order
                      }
                    />
                    {values.customer_id && (
                      <div className="w-fit">
                        <BadgeButton onClick={(e) => setShowInvoice(true)}>
                          Lihat Invoice Outstanding{" ("}
                          {
                            customerInvoices(values.customer_id)?.filter(
                              (invoice) => invoice.status !== 3
                            ).length
                          }
                          {")"}
                        </BadgeButton>
                      </div>
                    )}
                  </div>
                  <div className="h-full grid grid-cols-2 items-center gap-y-1 gap-x-2 text-right w-fit pr-2">
                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Subtotal:{" "}
                    </div>
                    <div className="pr-7">
                      <Typography.Currency
                        number={localHelpers.subtotal(values.items)}
                      />
                    </div>

                    <div className="text-xs uppercase text-gray-600 text-right font-light">
                      Ongkir (
                      {
                        <Typography.Currency
                          number={localHelpers.totalWeight(values.items)}
                          fontSize="xs"
                        />
                      }{" "}
                      gram)
                    </div>
                    <div className="flex items-center w-full justify-end">
                      {/* <Typography.Currency
                        // number={getDeliveryFee(values.address_id, values.items)}
                        number={delivery_fee}
                      /> */}
                      <InputMolecule
                        hideLabel={true}
                        type="number"
                        name={`delivery_fee`}
                        onChange={(e) => {
                          setDeliveryFee(Math.abs(Number(e.target.value)));
                          setNeedUpdateDeliveryFee(false);
                        }}
                        onBlur={handleBlur}
                        value={delivery_fee}
                        errorMessage={
                          getIn(errors, `delivery_fee`) &&
                          getIn(touched, `delivery_fee`) &&
                          getIn(errors, `delivery_fee`)
                        }
                        align="right"
                      />
                      <IconButton
                        icon={ArrowPathIcon}
                        onClick={(e) => {
                          getDeliveryFee(
                            values.address_id,
                            values.items,
                            selectedCourier
                          );
                          // setNeedUpdateDeliveryFee(false);
                        }}
                        disabled={!needUpdateDeliveryFee}
                        color={needUpdateDeliveryFee ? "accent" : "secondary"}
                      />
                    </div>
                    <div className="text-xs uppercase text-gray-600 text-right font-bold">
                      Total:{" "}
                    </div>
                    <div className="pr-7">
                      <Typography.Currency
                        number={
                          +localHelpers.subtotal(values.items) + delivery_fee
                        }
                      />
                    </div>
                  </div>
                </FormSummary>
              </div>
            </div>

            <ModalMolecule
              show={showInvoice}
              title="Invoice yang belum lunas"
              onClose={() => setShowInvoice(false)}
            >
              {customerInvoices(values.customer_id)?.filter(
                (invoice) => invoice.status !== 3
              ).length > 0 ? (
                <Table.Wrapper>
                  <Table.Header>
                    <Table.HeaderRow>
                      <Table.Heading>Kode</Table.Heading>
                      <Table.Heading align="right">Total Tagihan</Table.Heading>
                      <Table.Heading align="right">Pembayaran</Table.Heading>
                      <Table.Heading align="right">Kredit</Table.Heading>
                      <Table.Heading align="right">Kurang/Lebih</Table.Heading>
                    </Table.HeaderRow>
                  </Table.Header>
                  <Table.Body>
                    {customerInvoices(values.customer_id)?.map(
                      (invoice, key) => {
                        if (invoice.status !== 3) {
                          return (
                            <Table.BodyRow key={key}>
                              <Table.Cell>
                                <BadgeButton
                                  // onClick={(e) =>
                                  //   navigate("/invoice/detail/" + invoice.id)
                                  // }
                                  onClick={() =>
                                    window.open(
                                      "/invoice/detail/" + invoice.id,
                                      "_blank",
                                      "noopener,noreferrer"
                                    )
                                  }
                                >
                                  {invoice.inv_code}
                                </BadgeButton>
                              </Table.Cell>
                              <Table.Cell align="right">
                                <Typography.Currency
                                  number={invoice.total_amount}
                                ></Typography.Currency>
                              </Table.Cell>
                              <Table.Cell align="right">
                                <Typography.Currency
                                  number={invoice.total_payment}
                                ></Typography.Currency>
                              </Table.Cell>
                              <Table.Cell align="right">
                                <Typography.Currency
                                  number={invoice.credit_amount}
                                ></Typography.Currency>
                              </Table.Cell>
                              <Table.Cell align="right">
                                <Typography.Currency
                                  color={
                                    Number(invoice.total_amount) >
                                    Number(invoice.total_payment) +
                                      Number(invoice.credit_amount)
                                      ? "red"
                                      : "green"
                                  }
                                  number={
                                    Number(invoice.total_payment) +
                                    Number(invoice.credit_amount) -
                                    Number(invoice.total_amount)
                                  }
                                />
                              </Table.Cell>
                            </Table.BodyRow>
                          );
                        }
                      }
                    )}
                  </Table.Body>
                </Table.Wrapper>
              ) : (
                <p>Tidak ada invoice yang belum lunas.</p>
              )}
            </ModalMolecule>

            <ModalMolecule
              show={duplicateOrder && !confirmedDuplicateOrder}
              title="Duplicate Order"
              onClose={() => {
                setConfirmedDuplicateOrder(false);
                setDuplicateOrder(false);
              }}
            >
              <p>
                Terdapat order untuk <strong>customer yang sama</strong> di{" "}
                <strong>hari yang sama</strong>. Apakah Anda ingin tetap
                melanjutkan?
              </p>
              <div className="fixed bottom-2 left-0 sm:left-auto right-0 sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:absolute sm:rounded-b-lg sm:border-0 ">
                <Button
                  onClick={() => {
                    setConfirmedDuplicateOrder(true);
                    return true;
                  }}
                  type="submit"
                >
                  Lanjut
                </Button>
              </div>
            </ModalMolecule>

            <div className="fixed bottom-0 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
              <Button
                onClick={() => runValidations(values)}
                type="submit"
                disabled={isLoading}
              >
                {/* Tambah Order{confirmedDuplicateOrder && " (duplikat)"} */}
                {isLoading
                  ? "Loading..."
                  : `Tambah Order${
                      confirmedDuplicateOrder ? " (duplikat)" : ""
                    }`}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </MainLayout>
  );
}

export default CreateSalesOrderPage;
